import React from 'react'

function Error({closeToast,toastProps,message}) {
    return (
        <div>
        <div className='text-danger' role="alert">
            {'this is an error'}
         </div>
        </div>

    )
}

export default Error;
