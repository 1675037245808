import React from "react";
import { useEffect, useRef } from "react";
import Spinner from "../Components/Spinner";
import { Formik, Field, formik, Form, ErrorMessage, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import {
  FetchAPI,
  FetchAPILocal,
  postAPI,
  updateAPI,
  uploadImage,
  deleteImage,
  update} from "../../Api/api";
import { useState } from "react";
import Alert from "../Components/alert";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { initialValues, savedValues } from "./formValues";
import { isValidData, Data, fetchCoordinates, apply, openHoursCreator, setBusinessId, GoogleApiToken, LoadOptionsFormatter, business_categories_change, business_sub_categories_change, ValidationSchema2, validationSchema, UpdateProfilePic, getBusinessCategory, formatCategories, categoriesFormatter, fetchInformation, ValidationSchema3 } from './utils';
import Fields from "./components/Fields";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { baseUrl } from '../../Api/baseUrl';
import CreatableSelect from "react-select/creatable";
import Modal from './components/modal';
import Storehours from "./components/storehours";
import { toast } from 'react-toastify';
import RadiusFeild from './components/radius';

//API calls are being imported from here as functions.
import { update_application, UpdateBusiness } from './utils';
import AppClipCard from "./components/appClipCard";
var uniqid = require('uniqid');



//refer formik tuitoal on world wide web
export default function BusinessProfile(props) {

  const [review, setReview] = useState(true)
  const [storePrivacy, setStorePrivacy] = useState(true)
  let history = useHistory();
  const [inputText, setInputText] = useState(true)
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  //do we areally require the users position.
  const [position, setPosition] = useState(null);
  const [upLoader, setUploader] = useState(false)
  const [accepted, setAccepted] = useState(false);
  const [miniLoader, setMiniLoader] = useState(true)
  const [hasPhysicalAddress, setHasPhysicalAddress] = useState(true)
  //toggles the values of the
  const [cantFind, setCantFind] = useState(false)
  const [infoError, setInfoError] = useState({})
  //handles error at the time of submitting.
  const [error, setError] = useState(false)

  //axios cancel token ref for storing the cancel token.
  const cancelToken = useRef(null)
  const alertRef = useRef(null);

  //for the react select options 
  const [buss_category, set_buss_category] = useState([])
  //for default options when loading a saved data from the api.
  const [default_buss_category, set_default_buss_category] = useState([]);
  const [sub_category, set_sub_category] = useState([])
  const [default_sub_category, set_default_sub_category] = useState([]);
  const [message, setMessage] = useState({ type: "", message: "", });
  const [buttonLoader, setButtonLoader] = useState(false);
  const [app_clip_data, set_app_clip_data] = useState([]);
  const [checkImage, setcheckImage] = useState("");

  useEffect(() => {

    let info = JSON.parse(localStorage.getItem("user"));

    let bussID = info.twbusiness;
    let token = info.token;
    //cancelling api token need to be required here
    handleAppClipApi(token)

    FetchAPI(`/temp-businesses/?user=${info.userID}`, token)
      .then((response) => {
        //the first criteria should not be there.
        if (response.data.length > 0 && response.data[0].isApproved) {
          // console.log(response.data);
          setAccepted(true);
          //this business ID gets changed if pre owned business gets approved.
          fetchBusiness(response.data[0].twbusiness.id, token)
          setBusinessId(response.data[0].twbusiness.id)


        } else {
          fetchApplication(info, token);
          // appendDataInForm(response.data);
        }

        fetchBusinessCategories(token)
      })
      .catch((error) => {
        console.log(error);
      });



    return () => { };
  }, []);

  let changeStorePrivacy = () => {
    setStorePrivacy(!storePrivacy);
  }
  let changeReview = () => {
    setReview(!review);
  }

  function fetchBusiness(bussID, token) {
    FetchAPI(`/twbusinesses/${bussID}`, token)
      .then((result) => {
        // console.log(result.data)
        if (!Array.isArray(result.data)) {
          appendDataInForm([{ ...result.data }]);
        }

      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  //loads options from the api formats it with the help of react asnyc select.
  async function loadOptions(inputValue, callback) {
    try {

      setMiniLoader(true)
      if (cancelToken.current) {

        cancelToken.current.cancel()
      }

      cancelToken.current = axios.CancelToken.source()

      let result = await axios.get(`${baseUrl}/temp-businesses/search?searchtext=${inputValue}&token=${GoogleApiToken}`,
        {
          cancelToken: cancelToken.current.token,
        }
      );

      setMiniLoader(false)



      if (result.data.length > 0) {

        let format = result.data.map((places, index) => {
          let data = new LoadOptionsFormatter(places.name, places.placeId, places.address)

          return data;
        });


        callback(format)


      }

    } catch (error) {

      // console.log(error.message);


    }
  }

  //handles the change for the react-slect
  async function handleChange(formik, values) {

    if (values) {
      setMiniLoader(true)
      //the values are coming from load options method.
      formik.setFieldValue("Address", values.address, false);
      formik.setFieldValue("placeId", values.id, false);
      formik.setFieldValue("name", values.label, false);


      try {
        //fetches info with the help of the place ID and returns a formatted data using google maps API.
        let response = await fetchInformation(values.id);


        let info = response.data;

        //checks if the API is returning a valid data in state,city,country.
        let errors = isValidData(info);
        setInfoError(errors);

        if (Object.keys(errors).length > 0) {
          let error_string = Object.keys(errors).toString();

          toast.error(`Could not find ${error_string}`, { autoClose: 2000 })
        }


        formik.setFieldValue("lat_lng", info.lat_lng, false);
        formik.setFieldValue("website", info.website, false);
        formik.setFieldValue("state", info.state, false);
        formik.setFieldValue("country", info.country, false);
        formik.setFieldValue("city", info.city, false);

        setMiniLoader(false)
      } catch (err) {
        toast.error(`${err.message}`, {
          autoClose: 2000
        })
      }
    }
  }

  const getSubCategory = async (category) => {

    if (category && category.length) {
      const categoryIds = category.map(c => c.id)
      let info = JSON.parse(localStorage.getItem('user'))
      let token = info.token;
      const res = await FetchAPI(`/businesssubcategories/businesscategory?q=${JSON.stringify(categoryIds)}`, token);

      if (res.data && res.data.length) {
        const newArr = []
        res.data.forEach(el => {
          newArr.push(...el)
        })
        let uniqueArr = [...new Set(newArr)];
        set_sub_category(formatCategories(uniqueArr))
      }
    }
  }

  //fetches the prior applciation only if the status is pending
  function fetchApplication(params, token) {
    // let ID = JSON.parse(localStorage.getItem('user'));
    FetchAPI(`/temp-businesses/?user=${params.userID}`, token)
      .then((result) => {

        if (result.data.length > 0) {
          setMessage({
            type: "alert-info",
            message: "Hang on tight!  We are reviewing your application.",
          });
          appendDataInForm(result.data);
          // getMyLocation()
        } else {
          setMessage({
            type: "alert-primary",
            message:
              "Fill in your business details so that we can start with your application",
          });
          setLoading(false);
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  //data gets appended in the form for the values so that formik can use it
  function appendDataInForm(data) {
    if (Array.isArray(data)) {

      savedValues.name = data[0].name;
      savedValues.buss_category = data[0].buss_category;
      savedValues.Buss_subcategory = data[0].Buss_subcategory;
      savedValues.about = data[0].about;
      savedValues.website = data[0].website;
      savedValues.phone = data[0].phone;
      savedValues.Address = data[0].Address;
      savedValues.GoogleRating = data[0].GoogleRating;
      savedValues.placeId = data[0].placeId;
      savedValues.city = data[0].city;
      savedValues.state = data[0].state;
      savedValues.country = data[0].country;
      savedValues.lat_lng = data[0].lat_lng;
      savedValues.email = data[0].email;
      savedValues.applicationId = data[0].id;
      savedValues.photos_url = data[0].photos_url;
      savedValues.photoId = data[0].photoId;
      savedValues.appClipUid = data[0]?.appClipUid;
      savedValues.reviewEnabled = data[0]?.reviewEnabled;
      savedValues.isPrivateStore = data[0]?.isPrivateStore;
      savedValues.hasPhysicalAddress = data[0]?.hasPhysicalAddress;

      setcheckImage(data[0]?.appClipUid)
      props.handleUrlScan(data[0]?.appClipUid)

      if (data[0].radius) {
        savedValues.radius = data[0].radius
      }


      //   console.log(data[0].photoId,data[0].photos_url)

      //formats the business categgoryu that  he has chosen.//refer utils for more info.
      let categories = getBusinessCategory(data[0].buss_category)
      let subCategories = getBusinessCategory(data[0].Buss_subcategory)

      // console.log(categories,subCategories);

      set_default_buss_category(categories)
      set_default_sub_category(subCategories)


      //have the look of the jSOn part, the open hours key has a Stringified array.
      //this peice of code splits the array into readable formats of the time.
      if (data[0].op_hours) {
        let openHours = JSON.parse(data[0].op_hours);

        openHours.forEach((time, index) => {

          let slots = time.split(",");
          // console.log(slots)

          //console.log("=-=-=-=-=-=-=-=-=-=-=-=--=-==--==--=")
          let slot1 = slots[0].split("y");
          slot1.shift()
          slot1 = slot1.toString().split("-")
          let finalSlot1 = slot1.toString().substr(1);
          finalSlot1 = finalSlot1.split(",")



          savedValues.op_hours[index].start = finalSlot1[0].trim();
          savedValues.op_hours[index].end = finalSlot1[1].trim();


          if (slots.length > 1) {
            //console.log(slots)
            let finalSlot2 = slots[1].split("-")
            savedValues.op_hours[index].start2 = finalSlot2[0].trim();
            savedValues.op_hours[index].end2 = finalSlot2[1].trim();
          }

        });


      }
    }
    setSaved(true);
    setLoading(false);
  }



  async function handleSubmit(value, event) {

    try {
      setButtonLoader(true);

      //tallies all the store in an array .
      let openHours = openHoursCreator(value.op_hours)

      //if the business is not being fetched from google, we fetch its co-oridnates from the Google Geocoding API
      if (cantFind) {
        let GeoCoding = await fetchCoordinates(value.Address + value.city + value.state + value.country)

        if (GeoCoding !== null) {
          value.lat_lng = `${GeoCoding.geometry.location.lat},${GeoCoding.geometry.location.lng}`;
          value.placeId = GeoCoding.place_id;
        }
        else {

          setMessage({ type: "alert-danger", message: "Please Enter a valid address Below, we Could not verify the address." });

          setTimeout(() => {
            setMessage({ type: "", message: "" });
          }, 4000)

          setButtonLoader(false);
          return

        }

        //assings the business a new unique ID.
        // value.placeId = uniqid.time()
      }

      let ID = JSON.parse(localStorage.getItem("user"));


      //calls a constructor function and creates an object
      const user = new Data(
        value.name,
        value.about,
        value.GoogleRating,
        ID.userID,
        value.website,
        value.city,
        JSON.stringify(openHours),
        value.country,
        value.photos_url,
        value.placeId,
        value.photoId,
        value.buss_category,
        value.Buss_subcategory,
        value.lat_lng,
        value.Address,
        value.phone,
        value.email,
        value.state,
        value.radius,
        value.appClipUid,
        value.reviewEnabled,
        value.isPrivateStore,
        value.hasPhysicalAddress
      )
      //  if the applciation is already submitted, update will be made, if not submit will be made.
      if (!accepted) {
        //in this case only the ID will not be saved.
        if (!saved) {

          let applyStatus = await apply(user);
          if (applyStatus) {
            if (applyStatus.status === 0) {
							toast.error(applyStatus.message, { autoClose: 2000 });
							setButtonLoader(false);
							return;
						}
            setMessage({ type: "alert-success", message: "Application Submitted Successfully" });
            setButtonLoader(false);
            alertRef.current.scrollIntoView({ behaviour: "smooth" });
            value.applicationId = applyStatus.id;
            setBusinessId(applyStatus.id)
            setTimeout(() => history.push("/home"), 1000)
          }
          setSaved(true)


        } else {

          let updateStatus = await update_application(user, value.applicationId);
          if (updateStatus) {
            setBusinessId(value.applicationId)
            setMessage({ type: "alert-success fade show", message: "Your application has been updated", });
            setTimeout(() => {
              setMessage({ type: "", message: "" });
            }, 4000)

            setButtonLoader(false);
            alertRef.current.scrollIntoView({ behaviour: "smooth" });
          }
        }
      } else {

        setButtonLoader(true)
        //updates in the business collection.
        let updateStatus = await UpdateBusiness(value.applicationId, user)

        //updates in the temp business collection.
        let updateStatusTemp = await update_application(user, value.applicationId);

        if (updateStatus) {
          toast.info('Profile updated', { autoClose: 2000 })
          setButtonLoader(false)

        }
      }

    } catch (error) {

      //this part remains in the valid UI message
      console.log(error)
      toast.error('Error in Performing Action', { autoClose: 2000 })
      setButtonLoader(false);

    }

  }


  async function fileUpload(e, formik) {
    // console.log(e.target.files)
    setUploader(true)
    //if there is a photo previously, dlete that photo and move add new .
    if (formik.values.photoId.length > 0 && formik.values.photos_url.length > 0) {
      await deleteImage(`/upload/files/${formik.values.photoId}`).catch(err => {
      })

    }


    const formData = new FormData();


    formData.append("files", e.target.files[e.target.files.length - 1]);

    uploadImage(`/upload`, formData)
      .then((result) => {
        //check in the utils
        let op_hours = openHoursCreator(formik.initialValues.op_hours);

        //sets the formik values to latest fileds
        formik.setFieldValue("photos_url", result.data[0].url);
        formik.setFieldValue("photoId", result.data[0].id);


        //this piece of code here formats data hits an api which updates the buisinees ,photo url feilds without the help of clicking update button in the UI
        let data = UpdateProfilePic(result.data[0].url, result.data[0].id, JSON.stringify(op_hours), formik.initialValues.buss_category)

        // if there is new userr or if the business has not been appoved yet. Update in twbsiness collection or temp -Business collection and vice versa.
        if (formik.initialValues.applicationId.length > 0 && accepted) return update(`/twbusinesses/${formik.initialValues.applicationId}`, data)
        else if (formik.initialValues.applicationId.length > 0 && !accepted) return update(`/temp-businesses/${formik.initialValues.applicationId}`, data)

      }).then(updated => {
        if (updated) toast.info('Picture updated', {
          autoClose: 2000
        })
        //removes the content of the filelist.
        document.getElementById('up_file').value = []

      })
      .catch((err) => {
        toast.error(`${`There seems to be an error`}`, {
          autoClose: 2000
        })
      }).then(() => {
        //this part of code runs irrespective of whatever the promise resolves into.
        setUploader(false)
      })


  }

  function updateBusiness(id, data) {
    setButtonLoader(true);

    update(`/twbusinesses/${id}`, data)
      .then((result) => {
        //  console.log(result);
        // setMessage({
        //   type: "alert-success fade show",
        //   message: "Your profile is updated",
        // });

        toast.info('Profile updated', {
          autoClose: 2000
        })

        alertRef.current.scrollIntoView({ behaviour: "smooth" });
        setButtonLoader(false);
      })
      .catch((err) => {
        console.log(err);

        setMessage({
          type: "alert-danger fade show",
          message: "Error in updating profile",
        });
        setButtonLoader(false);
      });
  }

  //fetches and formats them.
  async function fetchBusinessCategories(token) {
    try {


      //promise. all settled is used when we have to call multiple API which are not dependent on each other.

      // it returns a status of each and every API called with response of respective to resolve or reject.
      let status = await Promise.allSettled([
        FetchAPI(`/businesscategories`, token),
        // FetchAPI(`/businesssubcategories`, token)
      ])
      if (status[0].status === 'fulfilled' && status[0].value?.data?.length > 0) set_buss_category(formatCategories(status[0].value.data.map(el => ({ ...el, name: el.category }))))
      // if(status[1].status==='fulfilled'&&status[1].value?.data?.length>0) set_sub_category( formatCategories(status[1].value.data))


      // if(categories.data.data.length > 0) formatCategories(categories.data.data)

      // if(subcategories.data.data.length > 0) formatCategories(subcategories.data.data)




    } catch (error) {

      console.log(error)

    }

    finally {
      setMiniLoader(false)
    }


  }

  //deletes the proifle pic of the business.
  function deleteFile(formik) {
    setUploader(true);

    deleteImage(`/upload/files/${formik.values.photoId}`)
      .then((result) => {
        let op_hours = openHoursCreator(formik.initialValues.op_hours);
        let data = UpdateProfilePic('', '', JSON.stringify(op_hours), formik.initialValues.buss_category)

        if (formik.initialValues.applicationId.length > 0 && accepted) return update(`/twbusinesses/${formik.initialValues.applicationId}`, data)
        else if (formik.initialValues.applicationId.length > 0 && !accepted) return update(`/temp-businesses/${formik.initialValues.applicationId}`, data)

      }).then((updated) => {
        if (updated) console.log(updated, 'updated successfully')
      })
      .catch((err) => {

        console.log(err);


      }).then(() => {

        //does not matter if promise resolved or gets rejected, this part od then will always run.
        setUploader(false);
        formik.setFieldValue("photos_url", "");
        formik.setFieldValue("photoId", "");
      })
  }

  //toggles the form from Manual mode to automatic Mode.
  function toggleForm() {
    setCantFind(!cantFind)
    setInputText(!inputText)
  }

  const handleAppClipApi = (token) => {
    FetchAPI(`/app-clips`, token)
      .then((res) => {
        // console.log("res === ", res.data)
        let data = res?.data ? res?.data : []
        set_app_clip_data(data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlecheckImage = (Uid, formik) => {
    formik.setFieldValue("appClipUid", Uid)
    props.handleUrlScan(Uid)
  }

  return (
    <>
      {loading ?
        <div className="d-flex justify-content-center mt-5 w-100">

          <Spinner small={false}></Spinner>
        </div>
        :
        <div>
          <div className="d-flex  justify-content-center mt-3" ref={alertRef}>
            {saved ?
              <Alert type={message.type} message={message.message}></Alert>
              :
              <Alert type={message.type} message={message.message}></Alert>
            }
          </div>

          <Formik
            initialValues={saved ? savedValues : initialValues}
            onSubmit={handleSubmit}
            validationSchema={cantFind ? (hasPhysicalAddress ? ValidationSchema2 : ValidationSchema3) : validationSchema}
          >
            {(formik) => (
              <Form>
                <div className="card_wrap mb-4 border_top">
                  <div className="card_header2 position-relative bg_ex_light">
                    <div className="avtr_box">
                      <div
                        className={
                          !formik.values.photos_url.length > 0 ?
                            "items_images position-relative bg_light" : ''
                        }
                      >
                        <img
                          src={formik.values.photos_url}
                          className="items_images position-relative "
                        ></img>

                        <button htmlFor="up_file" type="button"
                          className="edit_btn focus_out over_flow_hidden"
                        >
                          <label
                            htmlFor="up_file"
                            className="bi bi-pencil-fill mb-0"
                          ></label>
                          <input
                            accept="image"
                            type="file"
                            onChange={(e) => fileUpload(e, formik)}
                            className="upload_input"
                            id="up_file"
                          ></input>
                        </button>
                      </div>
                      <button
                        className="cs_button d-block text_danger mt-3 btn_focus"
                        onClick={() => deleteFile(formik)}
                        disabled={
                          formik.values.photos_url.length > 0 ? false : true
                        }
                        type="button"
                      >
                        {upLoader ? <Spinner small={true}></Spinner> : 'Delete'}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main_head d-flex sm_col_flex ">
                  <p className="title m-0">Business Profile</p>
                  <div
                    className="btn-group ml-auto sm_btn_group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button type="button" data-toggle="modal" data-target="#exampleModalLong" className="cs_btn out_line2 btn_focus">Cancel</button>
                    <button type="submit" className={`pointer cs_btn w-100 ${!formik.dirty ? 'bg-secondary' : 'bg_prime'}   border-0 btn_focus`} disabled={!formik.dirty || buttonLoader}>
                      {buttonLoader ? <Spinner small={true} /> : saved ? "Update" : "Apply"}
                    </button>

                  </div>
                </div>

                <div className="card_body_1 card_wrap pt_7r  animate__animated animate__fadeIn mb-4">
                  <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                    <div className="left_edit position-relative">
                      <label className="mb-0 items_text position-absolute" style={{ top: "-18px" }}>Store Name</label>
                    </div>
                    <div className="right_edit w-100">

                      {accepted || cantFind ? <Field
                        id="name"
                        type="text"
                        name="name"
                        autoComplete="true"
                        className={`form-control ${formik.touched.name &&
                          formik.errors.name
                          ? "border border-danger"
                          : ""
                          } items_text cs_input border_1 focus_out`}
                        placeholder={`${formik.touched.name &&
                          formik.errors.name
                          ? formik.errors.name
                          : "Name"
                          }`}
                      /> : (
                        <div>
                          <AsyncSelect
                            placeholder="Enter Store Name" isLoading={miniLoader}
                            value={formik.values.name.length > 0 && {
                              label: formik.values.name,
                              value: formik.values.applicationId,
                            }}
                            getOptionLabel={(option) => `${option.label + `:`}
                        ${option.address ? option.address : ""}`}
                            className={` ${formik.touched.name && formik.errors.name
                              && "border border-danger"}`}
                            loadOptions={loadOptions}
                            onChange={(value) => {
                              handleChange(formik, value);
                            }}
                          />
                        </div>
                      )}
                      {!accepted && <p className="float-right mt-2  text-primary font-italic" onClick={toggleForm} style={{ fontSize: '14px', marginBottom: '-9px', cursor: 'pointer' }}>{inputText ? "I can’t find it - Create new" : "Autofill - find it in the list"}</p>}
                    </div>

                  </div>

                  <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                    <div className="left_edit">
                      <label className="mb-0 items_text">Store Category</label>
                    </div>
                    <div className="right_edit w-100">
                      <CreatableSelect id="buss_category" placeholder="Enter  name" defaultValue={default_buss_category} onChange={(newValue) => { business_categories_change(newValue, formik); getSubCategory(newValue) }} options={buss_category} isDisabled={miniLoader} isLoading={miniLoader} isMulti name="buss_category" className={`${formik.touched.buss_category && formik.errors.buss_category ? 'border border-danger' : ''}`} />

                    </div>
                  </div>


                  <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                    <div className="left_edit">
                      <label className="mb-0 items_text">Store SubCategory</label>
                    </div>
                    <div className="right_edit w-100">
                      <CreatableSelect id="buss_category" defaultValue={default_sub_category} onChange={(newValue) => business_sub_categories_change(newValue, formik)} options={sub_category} isDisabled={miniLoader} isLoading={miniLoader} isMulti name="buss_category" className={`${formik.touched.Buss_subcategory && formik.errors.Buss_subcategory ? 'border border-danger' : ''}`} />
                    </div>
                  </div>
                  {/* we are only allowing to set radius if the user has been accepted */}
                  <RadiusFeild disableDecrementButton={formik.values.radius <= 0 ? true : false} name="radius" type="number" handler={formik} value={Number(formik.values.radius).toString()} label="Active Radius" touched={formik.touched.radius} error={formik.errors.radius} placeholder="Your business active area" />

                  <Fields type="text" label="Store Description" name="about" placeholder="Few Words About Your Store" touched={formik.touched.about} error={formik.errors.about}></Fields>
                  <Fields type="email" label="Store Email" name="email" placeholder="Email of your Store" touched={formik.touched.email} error={formik.errors.email}></Fields>
                  <Fields type="text" label="Store Website" name="website" placeholder="Enter any website" touched={formik.touched.website} error={formik.errors.website}></Fields>
                  <Fields type="tel" label="Store Phone Number" name="phone" placeholder="+X(XXX)-XXX-XXXX" touched={formik.touched.phone} error={formik.errors.phone} ></Fields>


                  {/* {cantFind &&
                    <>
                        <Fields type="text" label="Store Country" name="country"  placeholder="Country" touched={formik.touched.country} error={formik.errors.country} ></Fields>
                        <Fields type="text" label="Store State" name="state"  placeholder="State" touched={formik.touched.state} error={formik.errors.state} ></Fields>
                        <Fields type="text" label="Store City" name="city"  placeholder="City" touched={formik.touched.city} error={formik.errors.city} ></Fields>
                    </>

                  } */}

                  <div className="mb-4">
                    <Field type="radio" checked={hasPhysicalAddress} value="true" onClick={(e) => setHasPhysicalAddress(true)} name="physicalAddress" />
                    <span className="mb-4 mx-1 items_text">Has Physical Address</span>
                    <div className="checkbox-left">
                      <Field className="checkbox-margin" type="radio" value="false" checked={!hasPhysicalAddress} onClick={(e) => setHasPhysicalAddress(false)} name="physicalAddress" />
                      <span className="mb-4 mx-1 items_text">Don't have physical address</span>
                    </div>
                  </div>
                  {(cantFind || infoError.country) && hasPhysicalAddress && <Fields type="text" label="Store Country" name="country" placeholder="Country" touched={formik.touched.country} error={formik.errors.country} ></Fields>}
                  {(cantFind || infoError.state) && hasPhysicalAddress && <Fields type="text" label="Store State" name="state" placeholder="State" touched={formik.touched.state} error={formik.errors.state} ></Fields>}
                  {(cantFind || infoError.city) && hasPhysicalAddress && <Fields type="text" label="Store City" name="city" placeholder="City" touched={formik.touched.city} error={formik.errors.city} ></Fields>}

                  <Fields type="text" label={!hasPhysicalAddress ? "Operational Area/Country" : "Store Address"} name="Address" placeholder={!hasPhysicalAddress ? "Operational Area/Country" : "Enter Store Address"} touched={formik.touched.Address} error={formik.errors.Address} ></Fields>

                  <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                    <div className="left_edit">
                      <label className="mb-0 items_text">Reviews allowed</label>
                    </div>
                    <div className="right_edit w-100">
                      <Field type="checkbox" className="cm-toggle" label="Review allowed" name="reviewEnabled" touched={formik.touched.reviewEnabled} error={formik.errors.reviewEnabled} onClick={() => changeReview()} ></Field>
                    </div>
                  </div>

                  <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                    <div className="left_edit">
                      <label className="mb-0 items_text">Is Private Store</label>
                    </div>
                    <div className="right_edit w-100">
                      <Field type="checkbox" className="cm-toggle" label="Review allowed" name="isPrivateStore" touched={formik.touched.reviewEnabled} error={formik.errors.reviewEnabled} ></Field>
                    </div>
                  </div>

                </div>
                <Storehours op_hours={formik.values.op_hours}></Storehours>
                {app_clip_data && app_clip_data?.length > 0 ? <AppClipCard formik={formik} app_clip_data={app_clip_data} handlecheckImage={handlecheckImage} ></AppClipCard> : ""}
              </Form>
            )}
          </Formik>
        </div>
      }
    </>
  );
}
