
import * as Yup from 'yup';


//initial values add item
export const initialValues ={
    name: '',
    action: '',
    description: '',
    twbusiness: '',
    image_url: '',
    forwardLink: '',
    category:{
      label:'',
      value:''
    },
    subcategory:{
      label:'',
      value:''
    },
    price: '',
    photoId: '',
    is_video: false,
    thumbnail: '',
    videoThumbnail: '',
    isThumbnailPortrait: false,
    isPortrait: false
    
}


//validation shema for add item 
export const validation = Yup.object().shape({
    name: Yup.string().required('Required'),
    action: Yup.string(),
    description: Yup.string(),
     category: Yup.object({
       label:Yup.string().required('label Reqired'),
       value:Yup.string().required('value Required')
     }),
     subcategory: Yup.object({
      label:Yup.string().required('label Reqired'),
      value:Yup.string().required('value Required')
    }),
    price: Yup.string(),
    forwardLink: Yup.string().test('is Valid', 'Not a valid phone or email', Validator)
  })



  //custom validator function  for yup 
export function Validator(value){

  if(value){
    const emailRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
    
    const phoneRegex = new RegExp(/^(\+91-|\+91|0)?\d{10}$/);
   
   
     let isValidPhone = phoneRegex.test(value);
     let isValidEmail = emailRegex.test(value);

     console.log(value?.includes("page.locol.partners"));
     if(value?.includes("page.locol.partners") || value?.includes("locol.partners/b")){
      return false
     }
     if (!isValidEmail && !isValidPhone ) {
       return false
     }
     return true

  }else return true
    }



    export function prototype(one,two){
      return {
          label:one?one:'',
          value:two?two:''
      }
  }
  



  /* imports for edut item */
  export let initialValues2 = {
    name: "",
    action: "",
    description: "",
    twbusiness: "",
    image_url: "",
    forwardLink: "",
    category: {
      label: "",
      value: "",
    },
    is_video:'',
    subcategory: {
      label: "",
      value: "",
    },
    photoId: "",
    price: "",
    thumbnail: '',
    videoThumbnail: '',
    isThumbnailPortrait: false,
    isPortrait: false

  };
  

  export function Santize(value){
  let obj={...value};
  obj.category=prototype(value.category.name,value.category.id)
  obj.subcategory=prototype(value.subcategory.name,value.subcategory.id)
  if(obj.is_video) obj.thumbnail=extractThumbnail(obj.image_url)   


   delete obj.twbusiness;
   delete obj.updatedAt;
   delete obj._id;
   delete obj.review
   delete obj.reviewratings
   delete obj.reviews

    initialValues2={...obj}



  }

  function extractThumbnail(awsUrl){

    let splitArray=awsUrl.split('.');
   
    return splitArray[3].substr(4)
   
    }