import React from 'react';
import { Link } from "react-router-dom";

export default class MyOffers extends React.Component {
    render() {
      return(
        <div className="center_box">
        <div className="wrapper_card text-center">
          <div className="img_box">
            <img src={require('../../Assets/image/img_2.png').default} className="img-fluid" alt="store_img" /> 
          </div> 
          <h2 className="title">Live Offer</h2>
          <p className="sub_title">Close by users will see your live offer first thing <br />in nearby page. </p>
          {/* <Link className="btn btn-primary mt_17 font_14" to="/add-live-offer">Add live offer</Link> */}
          <button className='btn btn-primary p-2 mt-2 w-50'> Coming Soon</button>
        </div>
      </div>
      )
    }
  }



