import React from "react";
import { Field, Formik } from "formik";

//a stateless component just for rendering and modularzation.

export default function RadiusFeild(props) {

  
   // console.log(props)

  function increment(){
     let a= parseFloat(props.handler.values.radius);
     a=a+0.1
     console.log(isNaN(a));
     
     
     props.handler.setFieldValue('radius', parseFloat(a.toFixed(1)));      
    
   
   }

   //
  function decrement(){
    let a= parseFloat(props.handler.values.radius);
    a=a-0.1
    console.log(isNaN(a));

    if(a>=0){
      props.handler.setFieldValue('radius', parseFloat(a.toFixed(1)));
    }
    
              

  }


  
  //handle the type change so that
  function handleTypeChange(event){

    if(event.target.value){
      if(parseFloat(event.target.value)!=NaN){
        props.handler.setFieldValue('radius',parseFloat(event.target.value))
      }else{
       console.log('not avalid number')

      }
    }

  }






  return (
    <div className="edit-item d-flex align-items-center  mb-4 form_col_sm">
      <div className="left_edit">
        <label className="mb-0 items_text">
          {props.label}
          <span className="text-secondary" id="radiusInfoTooltip" data-toggle="tooltip" data-placement="bottom" title="Add radius size if you would like us to only show your store to users within specific area around your business address. Or keep it blank if you want everyone to see your store.">
            (Miles)
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle mx-2" viewBox="0 0 16 16" >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </span>
        </label>
      </div>
      <div className="right_edit d-flex w-100 justify-content-between short-input">
        <input
          name={props.name}
          type={props.type}
          
          onChange={(e)=>handleTypeChange(e)}
          value={props.value}
          id={props.name}
          
          className={`form-control ${
            props.touched&&props.error
              ? "border border-danger"
              : ""
          } items_text cs_input border_1 focus_out`}
          autoComplete="true"
          placeholder={props.placeholder}
          step='0.1'
        />
        
       
     
      <button onClick={increment} type="button"  className="btn bg_prime m-2 "> +</button>
       
       <button onClick={decrement}   disabled={props.disableDecrementButton}   type="button" className="btn btn-secondary m-2">-</button>
      


      </div>
    </div>
  );
}
