//here is the list of all the API's that will be fired

import axios from 'axios';
import {baseUrl} from './baseUrl';

const LocalUrl='http://localhost:1337'

export const LoginAPI=(path,credentials)=>{

   // console.log(credentials)

    return axios({
         method:'POST',
         url:baseUrl+path,
         headers: { 
            'Content-Type': 'application/json'
          },
         data:credentials
         
    })
}

//if an auth  token is served it will hit with a different  options.
export const postAPI=(path,credentials,token)=>{

  // console.log(credentials)


  if(token){
    return axios({
      method:'POST',
      url:baseUrl+path,
      headers: { 
         'Content-Type': 'application/json',
         'Authorization': `Bearer `+ token
       },
      data:credentials
      
 })
  }else{
    return axios({
      method:'POST',
      url:baseUrl+path,
      headers: { 
         'Content-Type': 'application/json',
       },
      data:credentials
      
 })
  }

  
}


export const uploadImage=(path,multipartData)=>{

  return axios({
    method:'POST',
    url:baseUrl+path,
    data:multipartData,
    headers:{"Content-Type":"multipart/form-data"},
  })

}


export const deleteImage=(path)=>{
  return axios({
    method:'DELETE',
    url:baseUrl+path,
    headers:{"Content-Type":"application/json"}
  })
}



export const postAPILocal=(path,credentials)=>{

  console.log(credentials)

  return axios({
       method:'POST',
       url:LocalUrl+path,
       headers: { 
          'Content-Type': 'application/json'
        },
       data:credentials
       
  })
}


export const updateAPI=(path,credentials,type)=>{
 

  //returns local or live api hit on basis of type.
    

  if(type){
    return axios({
      method:'PUT',
      url:baseUrl+path,
      headers: { 
         'Content-Type': 'application/json'
       },
      data:credentials
      
 })
}else{
    return axios({
      method:'PUT',
      url:'http://localhost:1337'+path,
      headers: { 
         'Content-Type': 'application/json'
       },
      data:credentials
      
 })
  }

  
}


export const update=(path,data)=>{
  return axios({
    method:'PUT',
    url:baseUrl+path,
    headers: { 
       'Content-Type': 'application/json'
     },
    data:data
    
})
}



export const postImage = async function (route, data) {
  try {
      return await axios.post(baseUrl + route, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
  } catch (err) {
    console.log(err);
  }
};


export const deleteAPI=(path,token)=>{
  return axios({
  method:'DELETE',
  url:baseUrl+path,
  headers: { 
    'Content-Type': 'application/json',
    'Authorization': `Bearer `+token
  },
  })
}

export const FetchAPI=(path,token)=>{
 

  return axios({
    method:'GET',
    url:baseUrl+path,
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}

export const fetchAPIWithoutToken=(path,token)=>{
  return axios({
    method:'GET',
    url:baseUrl+path,
    headers: { 
      'Content-Type': 'application/json',
    },
  })
}

export const FetchAPILocal=(path)=>{
return axios({
  method:'GET',
  url:'http://localhost:1337'+path
})
}

export const FetchApiWithToken=(path,token)=>{
  return axios({
    method:'GET',
    url:baseUrl+path,
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
}