import * as Yup from 'yup';
import axios from 'axios';
import { postAPI,updateAPI,update, fetchAPIWithoutToken } from '../../Api/api';
import {baseUrl} from '../../Api/baseUrl';

export function Data(name,about,GoogleRating,user,website,city,op_hours,country,photos_url,placeId,photoId,buss_category,Buss_subcategory,lat_lng,Address,phone,email,state,radius,appClipUid,reviewEnabled,isPrivateStore){

  this.name=name
  this.about=about
  this.GoogleRating=GoogleRating
  this.user=user
  this.website=website
  this.city=city
  this.op_hours=op_hours
  this.country=country
  this.photos_url=photos_url
  this.placeId=placeId
  this.photoId=photoId
  this.buss_category=buss_category
  this.Buss_subcategory=Buss_subcategory
  this.lat_lng=lat_lng
  this.Address=Address
  this.phone=phone
  this.email=email
  this.state=state
  this.radius = radius
  this.appClipUid = appClipUid
  this.reviewEnabled = reviewEnabled
  this.isPrivateStore = isPrivateStore

}

export function openHoursCreator(hours){ 

  let op_hours=hours.map((hour)=>{

    return`${hour.type}:${hour.start+`-`}${hour.end},${hour.start2+`-`}${hour.end2}`;
  })
  

  return op_hours;
}

export function setBusinessId(id){
  let info = JSON.parse(localStorage.getItem("user"));

  info.twbusiness = id

  localStorage.setItem("user", JSON.stringify(info));
}


export const GoogleApiToken = "D5C45353-EC94-42B6-85F6-986EE6A34A0D";


export function LoadOptionsFormatter(name,id,address){
  this.label=name
  this.id=id
  this.address=address

}


export function UpdateProfilePic(photos_url,photoId,op_hours,buss_category){


  return {
    photos_url,
    photoId,
    op_hours,
    buss_category
  }
}

//splits a muti string and formats in form of label and value for react sleect to work.


export function getBusinessCategory(data){
      if(data.length>0){
        let sanitized;
        let parts=data.split(',')

        let final=parts.map((value,index)=>{
           
          if(index===0){
            sanitized=value.slice(1);
            sanitized=sanitizeHard(sanitized)


            return {
              label:sanitized,
              value:sanitized
              }
          }else if(index===parts.length-1){
            
            sanitized=value.slice(0,value.length-1);
            sanitized=sanitizeHard(sanitized)

            return{
              label:sanitized,
              value:sanitized
              }
          }else {
            return {
              label:value,
              value:value
            }

          }
        })

        return final;
      }else return []
      
}

function sanitizeHard(value){

  if(value.includes("]")){
    let indexof=value.indexOf("]")
    let finalValue=value.substring(1,indexof-1);
    
  
    return finalValue;

  }else {
    let a= value.substring(1,value.length-1)
    console.log(a)
    return a;
  } 

}


export function formatCategories(data){
   
 // console.log(data)
  
  let formattedCategories=[];
   data.forEach(category=>{
    if(category && category.name && category.name.toLowerCase()!='all'){
      let obj= {
        label:category.name,
        value:category.name,
        id: category.id
      }

      formattedCategories.push(obj)
   }
   })

   return formattedCategories;
}

 export const errorMessage=''


export function categoriesFormatter(data){
   let arr=data.map(info=>{
     return info.value
   })

   return arr;
}
 
export  const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LinkRegExp=/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm




//when user creates his own business.
export const ValidationSchema2=Yup.object().shape({
  name: Yup.string().required("Required"),
  buss_category: Yup.string().required("Required"),
  Buss_subcategory: Yup.string().required("Required"),
  Address: Yup.string(),
  lat_lng: Yup.string(),
  state:Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  phone: Yup.string().matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,'No a valid Phone no'),
 
})

export const ValidationSchema3=Yup.object().shape({
  name: Yup.string().required("Required"),
  buss_category: Yup.string().required("Required"),
  Buss_subcategory: Yup.string().required("Required"),
  // Address: Yup.string(),
  lat_lng: Yup.string(),
  // state:Yup.string().required("Required"),
  // country: Yup.string().required("Required"),
  // city: Yup.string().required("Required"),
  phone: Yup.string().matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,'No a valid Phone no'),
 
})



//validation data when the user selects the data from google selection
 export const  validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
   buss_category: Yup.string().required("Required"),
  Buss_subcategory: Yup.string().required("Required"),
  phone: Yup.string().matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,'No a valid Phone no'),
  Address: Yup.string(),
  email:Yup.string().email('Not a valid Email'),
  radius:Yup.number().test('is Valid', `Radius cannot be below 0`,validateRadius)
});



function validateRadius(value){

  if(parseFloat(value)<0)return false
  else return true;

}




//Async select API for react-select
 export function fetchInformation(placeId) {
  return axios.get(
    `${baseUrl}/temp-businesses/info?token=${GoogleApiToken}&placeId=${placeId}`
  );
}


//fetches the user location latitutde and longitude.
export const getMyLocation = () => {
  const location = window.navigator && window.navigator.geolocation;

  if (location) {
    location.getCurrentPosition(
      (position) => {
        const obj = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        console.log(obj)
          return obj;
      
      },
      (error) => { 
        return null;
        
      }
    );
  }
};


 export function business_sub_categories_change(newValue, formik) {


  if (newValue.length > 0) {
    //  console.log(newValue)
    let arr = categoriesFormatter(newValue)

    // console.log(arr)
    // set_default_buss_category(arr)
    formik.setFieldValue('Buss_subcategory', JSON.stringify(arr));
  //  console.log(formik.values)

  } else {
    // set_default_buss_category([]) 
    formik.setFieldValue('Buss_subcategory', '');
  }


}


export function business_categories_change(newValue, formik) {

 

  if (newValue.length > 0) {
    //  console.log(newValue)
    let arr = categoriesFormatter(newValue)

    console.log(arr)
    console.log(JSON.stringify(arr));
    // set_default_buss_category(arr)
    formik.setFieldValue('buss_category', JSON.stringify(arr));
  } else {
    // set_default_buss_category([]) 
    formik.setFieldValue('buss_category', '');
  }


}

export async function fetchCoordinates(address){

  const information = await fetchAPIWithoutToken(`/temp-businesses/searchGeoCode?address=${address}`)

  if(information.data.results.length>0){
    return information.data.results[0] 
  }else return null;

}


export  function apply(data){

    return  postAPI('/temp-businesses',JSON.stringify(data)).then(result=>{
      return result.data;
    }).catch(error=>{
        
       if(error.response.data.statusCode==500){
        throw new Error('This business has already been registered')
       }else throw error
    }) 
}

export  function update_application(data,applicationId){
  return update(`/temp-businesses/${applicationId}`,data).then(res=>{
     return res;

  }).catch(error=>{
    throw error;
  })


}

export  function UpdateBusiness(applicationId,data){

   return update(`/twbusinesses/${applicationId}`,data).then(res=>{
         
     return res;

    }).catch(error=>{
     throw error
    })
}

export function isValidData(data){
let obj={};
if(data.state.length===0) obj.state=true;

if(data.country.length===0) obj.country=true;

if(data.city.length===0) obj.city=true;


return obj;

}