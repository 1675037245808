import React, {useState, useEffect, useRef} from 'react';
import MyCatalog from '../Main/MyCatalog/MyCatalog';
import MyStore from '../Main/MyCatalog/MyStore';
import Edititem from '../Main/MyCatalog/Edititem';
import MyOffers from '../Main/Myoffer/MyOffers';
import Addliveoffer from '../Main/Myoffer/Addliveoffer';
import Additem from '../Main/MyCatalog/Additem';
import EditOffer from '../Main/Myoffer/EditOffer';
import BusinessProfile from '../Main/BusinessProfile/BusinessProfile';
import Setting from '../Main/Setting';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Home from '../Main/Home';
import RegisterUser from '../Main/Auth/registerUser';
import PrivateRouteDashboard from './privateRouteDashboard'; 

import { Route } from 'react-router-dom';
import ChangeStoreLayout2 from '../Main/MyCatalog/ChangeStoreLayout2';
import SignIn from '../Main/Auth/signIn';

const Catalouge=()=> {
    const isAuth = Boolean(localStorage.getItem("user"))
    const toggleButton = useRef(null);
    const [sidebar, setSidebar] = useState(false); 
    const [scanUrl, setScanUrl] = useState(""); 

    const outSideContent = (e) => {
        //console.log('entry', toggleButton.current, '&&',  e.target );
        if(toggleButton.current !== e.target){
            setSidebar(false);
        } 
    }
    
    useEffect(()=>{ 
        const body = document.querySelector('body');
        body.addEventListener('click',outSideContent)
    }, [])
    
    const handleUrlScan = (data) => {
        setScanUrl(data)
    }

    return (
        <div>
            {
                sidebar ? <div className={`backdrop_back`}></div> : null
            }
             {isAuth ? <>
            <div style={{overflow:"auto"}} className={`left sidebar bg-white ${sidebar ? `active` : ``}`}> 
               <Sidebar scanUrl={ scanUrl}/>
            </div>
            <div className="right main_container sm_right_container" >
                <header>
                    <Header/>
                    <div className="d-block d-lg-none">
                        <div className="header_menu_icon">
                            <i className="fas fa-stream" ref={toggleButton} onClick={()=> setSidebar(true)}></i>
                        </div> 
                    </div>
                </header>
                <div className="main_containers" >
                    <main className="p_36 mn-height">
                    <PrivateRouteDashboard exact path="/" component={Home} />
                        <PrivateRouteDashboard exact path="/my-store" component={MyStore} />
                        <PrivateRouteDashboard exact  path="/edit-item/:catalogID" component={Edititem} />
                        <PrivateRouteDashboard exact path="/my-offers" component={MyOffers} />
                        <PrivateRouteDashboard exact path="/add-item" component={Additem} />
                        <PrivateRouteDashboard exact path="/add-live-offer" component={Addliveoffer} />
                        <PrivateRouteDashboard exact path="/edit-offer" component={EditOffer} />
                        <PrivateRouteDashboard exact path="/business-profile" component={BusinessProfile} handleUrlScan={ handleUrlScan}/>
                        <PrivateRouteDashboard exact path="/Setting" component={Setting} /> 
                        <PrivateRouteDashboard exact path="/change-order" component={ChangeStoreLayout2} heading="Change Items"/>
                    </main>
                    <footer className="main-footer w-100 d-flex justify-content-between p_36">
                        <p>© Witty Lab 2022</p>
                        <p>© Witty Lab 2022</p>
                    </footer>
                </div>
            </div>
            </>
             :  <SignIn /> }
        </div>
    )
}

export default Catalouge
