import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form } from 'formik';
import { deleteAPI } from '../../Api/api';
import Spinner from '../Components/Spinner';
import { ToastContainer, toast } from 'react-toastify';

//formik initial values 
const initialValues = {
  checked: []
}


//formik handle submit button.
class MyStore extends React.Component {

  constructor() {
    super()
    this.state = {
      loader: false,
      loader2: false,
       check:false
    }
  }



componentDidMount() {

  //console.log(this.props.location);
}



//only works for deleteing a value .
  handleSubmit = (values) => {

    let businessID = localStorage.getItem('user')
    let userInfo = JSON.parse(businessID)

    this.setState({ loader2: true })

    let items = values.checked.map(function (id) {
      return deleteAPI(`/catalogues/${id}`,userInfo.token)
    })



    Promise.all(items).then(result => {

      console.log(result)
      this.setState({ loader2: false })

     

      let bussID = userInfo.twbusiness;
      let token = userInfo.token;

      this.props.fetch(bussID, token);

    this.toastHandler()


    }).catch(err => {
      console.log(err)
    })


  }


//selects all the checkboxes.
  checkall = (formik) => {
    
   if(this.state.check){
     formik.values.checked.length=0
     this.setState({check:false})
   }else{

    this.props.data.forEach((info)=>{
      info.catalog_categories.forEach((data)=>{
            data.items.forEach((items)=>{
                //  console.log(items)
                  formik.values.checked.push(items.id)
                  this.setState({check:true})
            })
      })
   })
  

  }

  


  }


//handles the react notification service
  toastHandler=()=>{

    toast.info("Item Deleted");



  }

  editFunction = (checked) => {
      this.props.history.push(`/edit-item/${checked}`)
  }
  


  render() {
    let setWidth = 55;
    if(window.innerWidth < 500){
      setWidth = 20
    }
    return (
      <>

       
        <Formik initialValues={initialValues} onSubmit={this.handleSubmit} >
          {(formik) => (

            <Form>
              <div className="main_head d-flex sm_col_flex">
                <p className="title m-0">My Catalog</p>
                <div className="btn-group ml-auto sm_btn_group" role="group" aria-label="Basic example">
                  <button type="submit" disabled={formik.values.checked.length >= 1 ? false : true} className="cs_btn out_line1 ml-0"><i className="mr-1 bi bi-trash"></i> {this.state.loader2 ? <Spinner small={true}></Spinner> : 'Delete'}</button>
                  <button type="button" onClick = {() => this.editFunction(formik.values.checked[0])} className="cs_btn out_line2" disabled={formik.values.checked.length == 1 ? false : true} ><i className="mr-1 bi bi-pencil"></i> Edit</button>
                  <Link type="button" to="/add-item" className="cs_btn bg_prime border-0" style={{textDecoration:"none"}}><i className="mr-1 bi bi-plus-lg"></i> Add Item</Link>
                  <Link type="button" to="/change-order" className="cs_btn bg_prime border-0" style={{textDecoration:"none"}} >Change Order</Link>
                </div>
              </div>
              <div style={{overflow:"auto"}} className="container_table tbl_border mb_40 table-responsive-md animate__animated animate__fadeIn  remove-bdr">

                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>
                        <label className="wrap_checkbox unset_position">
                          <input   {...formik.getFieldProps}   className="cs_check_box" onClick={()=>this.checkall(formik)} type="checkbox" />
                          <span className="check_mark"></span>
                        </label>
                      </th>
                      <th>NAME</th>
                      <th>CATEGORY</th>
                      <th>SUB-CATEGORY</th>
                      <th>PRICE</th>
                      <th>CTA</th>
                      <th>LINK</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.props.data.map((info) =>
                      info.show == false ? "" : 
                        info.catalog_categories.map((data, index1) =>
                        data.show == false ? "" :
                          data.items.map((items, index2) => (
                            items.isActive == false ? "" :
                            <tr key={index2}>
                              <td>
                                <label className="wrap_checkbox unset_position">
                                  {/* {console.log(items)} */}
                                  {/* <input type="checkbox" className="cs_check_box" name="checked" value={items.id}  > */}
                                  <Field name="checked">

                                    {({ field}) => (

                                      <div>
                                        <input type="checkbox" id={items.id}  {...field} value={items.id}   checked={field.value.includes(items.id)}   />
                                        <span className="check_mark"></span>
                                      </div>

                                      )}
                                     </Field>

                                     
                                  {/* <Field className="cs_check_box" name="checked" value={items.id}  id={items.name} type="checkbox" /> */}
                                 
                                </label>
                              </td>
                              <td>
                                <div className="table_user d-flex align-items-center">
                                  <div className="img_avtar mr-3">
                                    <img src={
                                      items?.videoThumbnail
                                      ?
                                      items?.videoThumbnail
                                      :
                                        (items.is_video
                                        ?
                                        `https://stream.locol.partners/${items.thumbnail}/thumb/${items.thumbnail}.jpg` 
                                        :
                                        items.image_url.length>0?items.image_url : require('../../Assets/image/blank.png').default)
                                      } className="img_fluid_table" alt="avtar img" />
                                  </div>
                                  <p className="mb-0">{items.name}</p>
                                </div>
                              </td>
                              <td>{info?.name && info.name?.length > setWidth ? info?.name.slice(0, setWidth) + "..." : info?.name}</td>
                              <td>{data?.name && data?.name?.length > setWidth ? data?.name.slice(0,setWidth) + "..." : data?.name}</td>
                              <td> {items.price} </td>
                              <td>{items.action}</td>
                              <td>{items.forwardLink ? <i className="bi bi-check-lg text_success"></i> : <i className="bi bi-check-lg text_danger"></i>}</td>
                            </tr>

                          ))
                        )
                      )
                    }
                  </tbody>
                </table>

              </div>

            </Form>
          )}

        </Formik>


      </>
    )
  }
}



export default withRouter(MyStore);