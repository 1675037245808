import React from 'react';

export default class Header extends React.Component {
    render() {
      return (
            <>
            <div className="d-none d-lg-block" style={{paddingTop:"6rem"}}>
              <div className="d-flex justify-content-end p_36">
                {/* <ul className="header_option m-0 p-0 d-flex align-items-center">
                  <li>
                    <div className="notification position-relative">
                      <i className="bi bi-bell"></i>
                      <span></span>
                    </div>
                    </li>
                  <li>

                    <div className="user_icon position-relative">
                      <img src={require('../Assets/image/user_icon.png').default} alt="user" className="img-fluid"/>
                      <span></span>
                    </div>

                  </li>
                </ul> */}
              </div>
            </div>
            </>
      )
    }
  }



