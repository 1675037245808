import React from 'react';
import '../../Assets/css/layout.css';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import ChangedStoreToggleItems2 from './ChangedStoreToggleItems2';
import { confirmBoxFunc, svgArrowFun, svgDragFun, svgFadeEyeFun1, svgFillEyeFun2 } from './SvgFuntions';
let whatlet;

class ChangedStoreToggle2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            eyeToggle2: true
        };
    }

    subCategoryEyeFunc = (value, hold1, index) => {
        value.items.every(data => data.isActive == false) ? whatlet = true : whatlet = false;
        value.items.map(datas => datas.isActive = whatlet);
        // this.props.handleUpdate();
        this.props.handleToggleChange("subCategory", hold1, index);
    }

    func1 = (i) => {
        this.setState({ ...this.state, [`toggle${i}`]: !this.state?.[`toggle${i}`] })
    }
    eyeFunc = () => {
        this.state.eyeToggle2 = !this.state.eyeToggle2
        this.setState({ ...this.state })
    }

    componentDidMount = () => {
        if (this.props?.value) {
            this.setState({ data: this.props.value })
        }
    }

    componentDidUpdate = (nextProps) => {
        if (this.props?.value != nextProps?.value) {
            this.setState({ data: this.props.value })
        }
    }

    render() {
        const that = this;
        const dragPropsproduct = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.data];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                // that.setState({ data });
                that.props.handleChangeCatagory([...data], that.props?.hold1, "catagory")
            },
            nodeSelector: 'div.tt1',
            handleSelector: 'a.ttw',
            offsetParent: 'div.uuiuiu'
        };
        return (
            <>
                <ReactDragListView {...dragPropsproduct}>
                    <div className='substore-text uuiuiu' style={{ width: "97%", marginLeft: "auto", marginTop: "0.5rem" }}>
                        {this.state.data?.length > 0 && this.state.data.map((value, index) => {
                            return (
                                <>
                                    <div key={`${index}${this.props.hold1}`} className='tt1 main-outer'>
                                        <div data-toggle="collapse" className='bg-gray-color' style={{ margin: ".8rem 0", padding: "0.7rem 0" }} data-target={`#yoo3${index}${this.props.hold1}`} onClick={() => this.func1(index)}>
                                            <span style={{ verticalAlign: "unset", paddingRight: "5px" }} >
                                                {svgArrowFun("300ms", this.state?.[`toggle${index}`])}</span>{value?.name || ""}<a className='ttw' style={{ float: "right" }}>
                                                <span onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (confirmBoxFunc(value.items.every(data => data.isActive == false))) {
                                                        this.subCategoryEyeFunc(value, this.props?.hold1, index)
                                                    }
                                                }}>
                                                    {/* this.props.handleToggleChange("subCategory", this.props?.hold1, index)  */}
                                                    {!value.items.every(data => data.isActive == false) ? svgFadeEyeFun1() : svgFillEyeFun2()}
                                                </span>
                                                {svgDragFun()}
                                            </a>
                                        </div>
                                        <div id={`yoo3${index}${this.props.hold1}`} className={this.props.eyeToggle ? "collapse" : ""}>
                                            <ChangedStoreToggleItems2 items={value?.items} handleUpdate={this.props.handleUpdate} handleChangeitem={this.props?.handleChangeitem} handleToggleChange={this.props.handleToggleChange} indexc={index} hold1={this.props?.hold1} />
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </ReactDragListView>
            </>
        );
    }
}

export default ChangedStoreToggle2;