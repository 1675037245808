import React from 'react';
import '../../Assets/css/layout.css';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import CategoryListItem from './categoryListItem';
import ChangeStoreDescription from './ChangeStoreDescription';
import { postAPI } from '../../Api/api';
import { baseUrl } from '../../Api/baseUrl';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

let count = 0;

class ChangeStoreLayout2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            button: false,
            loader: true,
            updateBtn: true
        };
    }

    componentDidMount = () => {
        this.handleAPI();
        count = 0;
    }

    handleToggleChange = (type, catInd, subInd, itemInd) => {
        const { data } = this.state;
        let newArr = data ?
            data.map((item, i) => {
                let catArr = item?.catalog_categories ? item.catalog_categories.map((obj, ind) => {
                    let itemsArr = obj?.items ?
                        obj.items.map((object, index) => {
                            if (type === "items" && index === itemInd && ind === subInd && i == catInd) {
                                return { ...object, itemShow: !object.isActive }
                            }
                            else return { ...object }
                        })
                        : []
                    return { ...obj, items: itemsArr }
                }) : []
                return { ...item, catalog_categories: catArr }
            })
            : []
        this.setState({ data: newArr });
    }

    handleAPI = () => {
        const twBusinessId = (JSON.parse(localStorage.getItem("user")))?.twbusiness;
        fetch(`${baseUrl}/catalog-categories/allcategories?bussinessID=${twBusinessId}`)
            .then(res => res.json()).then(data2 => {
                let arr = data2 ?
                    data2.map((item, i) => {
                        let catArr = item?.catalog_categories ? item.catalog_categories.map((obj, ind) => {
                            let itemsArr = obj?.items ?
                                obj.items.map((object, index) => {
                                    return { ...object }
                                })
                                : []
                            return { ...obj, items: itemsArr }
                        }) : []
                        return { ...item, catalog_categories: catArr }
                    })
                    : []
                this.setState({ data: arr, loader: false, button: false })
            })
            .catch(err => {
                this.setState({ ...this.state, loader: false, button: false })
                console.log(err)
            });
    }

    handleChangeCatagory = (dataa, i) => {
        const ddata = this.state.data.map((el, ii) => {
            if (i == ii) {
                return {
                    ...el,
                    catalog_categories: dataa
                }
            } else {
                return el
            }
        })
        this.setState({ data: ddata });
    }

    handleChangeitem = (dataa, ii, i) => {
        const ddata = this.state.data.map((el, ind) => {
            if (ind == ii) {
                const cItem = el.catalog_categories.map((e, index) => {
                    if (index == i) {
                        return { ...e, items: dataa }
                    } else {
                        return e
                    }
                })
                return { ...el, catalog_categories: cItem }
            } else {
                return el
            }
        })
        this.setState({ data: ddata });
    }

    handleUpdate = async () => {
        this.setState({ ...this.state, loader: true, button: true })
        let info = JSON.parse(localStorage.getItem("user"));
        let twBusinessId = info.twbusiness;

        let objData = {
            data: this.state.data,
            twBusinessId
        }
        try {
            const res = await postAPI(`/twbusinesses/order`, objData)
            if (objData.status == 1) {
            }
            if (res) {
                toast.success("Order Updated Successfully", {
                    toastId: "singleClick"
                  });
                this.setState({ ...this.state, loader: false, button: false, updateBtn: true })
            }
        } catch (error) {
            toast.error("something went wrong, please try again!");
            this.setState({ ...this.state, loader: false, button: false })
        }
        count = 1;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data != this.state.data) {
            if(count == 1){
                this.setState({ ...this.state, loader: false, button: false, updateBtn: false })
            }
            ++count;
        }
    }

    render() {
        const that = this;
        const dragPropsproduct = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.data];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ data });
            },
            nodeSelector: 'div.wr-p',
            handleSelector: 'a',
            offsetParent: 'div.substore-text'
        };
        return (

            <div>
                {this.state.button ? <button className="cs_btn border-0 update-btn" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" style={{ verticalAlign: "middle" }} role="status" aria-hidden="true"></span>
                    Loading...
                </button>
                    : <button type="button" className="cs_btn border-0 update-btn" style={this.state.updateBtn ? {backgroundColor : "gray"} : {backgroundColor : "#377dff"}} disabled={this.state.updateBtn} onClick={this.handleUpdate}>Update Order</button>
                }
                <h2 style={{ fontWeight: 600, margin: "3rem 0 1rem 0" }}>Your Store Front</h2>
                <p><ChangeStoreDescription /></p>
                {
                    this.state.loader ?
                        <div className="text-center" style={{ marginTop: "3rem" }}>
                            <div className="spinner-border" role="status" />
                        </div>
                        :
                        <div>
                            <ReactDragListView {...dragPropsproduct}>
                                {this.state.data.length > 0 && this.state.data.map((value, index) => {
                                    return <CategoryListItem key={`p-${index}`} handleUpdate={this.handleUpdate} index={index} handleToggleChange={this.handleToggleChange} value={value} handleChangeCatagory={this.handleChangeCatagory} handleChangeitem={this.handleChangeitem} />
                                })}
                            </ReactDragListView>
                        </div>
                }
            </div>
        );
    }
}

export default ChangeStoreLayout2;