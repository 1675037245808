import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { confirmBoxFunc, svgDragFun, svgFadeEyeFun1, svgFillEyeFun2 } from './SvgFuntions';

const ChangedStoreToggleItems2 = (props) => {

    const [data, setData] = useState([])
    const [eyeValue, setEyeValue] = useState(true)
    useEffect(() => {
        if (props?.items) {
            setData(props.items)
        }
    }, [props.items])

    const itemEyeFunc = (value, hold1, indexc, index) => {
        value.isActive = !value.isActive;
        // props.handleUpdate();
        props.handleToggleChange("items", hold1, indexc, index);
    }

    const dragPropsitems2 = {
        onDragEnd(fromIndex, toIndex) {
            const data2 = [...data];
            const item = data2.splice(fromIndex, 1)[0];
            data2.splice(toIndex, 0, item);
            // setData([...data2]);
            props.handleChangeitem([...data2], props?.hold1, props?.indexc)
        },
        nodeSelector: 'div.yy1',
        handleSelector: 'a.hhw',
        offsetParent: 'div.su12'
    };
    return (
        <ReactDragListView {...dragPropsitems2}>
            <div className='su12 main-outer' style={{ width: "96%", marginLeft: 'auto' }}>
                {data.length > 0 && data.map((value, index) => {
                    return (
                        <div key={index} className='yy1 bg-gray-color item-border'>
                            <div className='items-section'>
                                <span>{value?.name || ""}</span>
                                <a className='hhw' style={{ float: "right" }}>
                                    <span onClick={(e) => confirmBoxFunc(!value.isActive) ? itemEyeFunc(value, props?.hold1, props?.indexc, index) : ""}>
                                        {/* props.handleToggleChange("items", props?.hold1, props?.indexc, index)  */}
                                        {!value.isActive ? svgFillEyeFun2() : svgFadeEyeFun1()}</span>
                                    {svgDragFun()}
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </ReactDragListView>
    )
}

export default ChangedStoreToggleItems2;