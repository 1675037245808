import React from "react";

function Alert(props) {
  return (
    <div
      className={` alert ${props.type} alert-dismissible w-75 text-center `}
      style={{ position: "sticky", wordWrap: "space" }}
      role="alert"
    >
      {props.message}
      {/* <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button> */}
    </div>
  );
}

export default Alert;
