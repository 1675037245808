import React, { useEffect, useState } from "react";
import Check from "../../../Assets/image/check.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../Assets/css/layout.css';

function AppClipCard({ formik, app_clip_data, handlecheckImage }) {
  const [numberData, setNumberData] = useState(0);
  const [disableNav, setDisableNav] = useState({
    plus: false,
    minus: true
  })
  const [type, setType] = useState("");
  let navItems;
  let browserWidth = window.innerWidth;

  let responsiveScreen = {
    width0: {
      items: 2,
    },
    width400: {
      items: 2,
    },
    width767: {
      items: 3,
    },
    width992: {
      items: 4,
    },
    width1366: {
      items: 5,
    },
  }

  if (browserWidth <= 400) {
    navItems = responsiveScreen.width0.items;
  } else if (browserWidth >= 400 && browserWidth <= 766) {
    navItems = responsiveScreen.width400.items;
  } else if (browserWidth >= 767 && browserWidth <= 991) {
    navItems = responsiveScreen.width767.items;
  } else if (browserWidth >= 992 && browserWidth <= 1365) {
    navItems = responsiveScreen.width992.items;
  } else if (browserWidth >= 1366) {
    navItems = responsiveScreen.width1366.items;
  }


  useEffect(() => {
    setTimeout(() => {
      const appClipBox = document.getElementById("caraouselMainDiv")
      if(appClipBox){
        appClipBox.style.minHeight = `${appClipBox.offsetHeight}px`
      }
    }, 1000)
  }, [document.getElementById("caraouselMainDiv")])

  const handleNav = (types) => {
    if (numberData <= (app_clip_data?.length)) {
      setDisableNav({ ...disableNav, plus: false })
    }
    if (types == "minus") {
      setNumberData(numberData - navItems)
      setType(types)
    } else {
      setDisableNav({ ...disableNav, minus: false })
      setNumberData(numberData + navItems)
      setType(types)
    }
  }

  useEffect(() => {
    if (type == "minus") {
      if (numberData <= 0 || numberData < navItems) {
        setDisableNav({ ...disableNav, minus: true })
      }
    } else {
      if ((numberData + navItems) > app_clip_data?.length) {
        setDisableNav({ ...disableNav, plus: true })
      }
    }
  }, [numberData])

  return (
    <>
      <div className="carousel_container" id="caraouselMainDiv">
        <div className="card_wrap mb-4 animate__animated animate__fadeIn position-relative">
          <div className="card_head" style={{ paddingBottom: "6px" }}>
            <span className="items_text text_dark">AppClip Card</span>
          </div>
          <div className="card_head" style={{ paddingTop: "6px" }}>
            <span className="items_text_sub">
              AppClip allows your customers to access your catalog without having
              download the full app. Select the card that best applies to your
              catalog.
            </span>
          </div>
          <OwlCarousel loop={false} navRewind={false} mouseDrag={false} touchDrag={false} className='owl-theme' startPosition={numberData} margin={1} items={navItems} dots={false}>
            {app_clip_data && app_clip_data?.length > 0 && app_clip_data.map((item, index) => (
              <div key={index} className=" d-flex flex-column text-center mb-4" onClick={() => handlecheckImage(item?.Uid, formik)}>
                <div
                  className={`text-center ${item?.Uid == formik.values?.appClipUid
                    ? "custom_card"
                    : "custom_card2"
                    } mb-4 p-1 `}
                >
                  <img
                    src={item?.Image?.url}
                    style={{ borderRadius: "25px" }}
                  />
                </div>
                <span className="items_text">{item?.Name}</span>
                {item?.Uid == formik.values?.appClipUid ? (
                  <div className="text-center">
                    <img
                      src={Check}
                      style={{
                        maxHeight: "20px",
                        maxWidth: "20px",
                        margin: "0 auto",
                        marginTop: "17px",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
            }
          </OwlCarousel>
          <div className="owl-button">
            <button className="new-btn-css frst-btn" style={{ backgroundColor: disableNav.minus ? "grey" : "" }} disabled={disableNav.minus} type="button" onClick={() => handleNav("minus")}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: disableNav.minus ? "grey" : "", boxShadow: disableNav.minus ? "none" : "" }} height="40" width="40">
                <path d="M23.333 29.417 13.917 20l9.416-9.458 1.5 1.5L16.875 20l7.958 7.917Z" />
              </svg>
            </button>
            <button className="new-btn-css scnd-btn" style={{ backgroundColor: disableNav.plus ? "grey" : "" }} disabled={disableNav.plus} type="button" onClick={() => handleNav("plus")}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ backgroundColor: disableNav.plus ? "grey" : "", boxShadow: disableNav.plus ? "none" : "" }} height="40" width="40">
                <path d="m15.667 29.417-1.5-1.5L22.125 20l-7.958-7.958 1.5-1.5L25.083 20Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppClipCard;
