import React, { useState } from 'react';
import Image from '../../Assets/image/arrows.png'

function Access() {

  const [color,setColor] =  useState("#faf675") 

//   useEffect(() => {

//   }, [])

  return (
    <>
      <style>{'body, html {background-color: '+color+';}'}</style>
      <div className="position_center">
        <div className="text-center">
          <img src={Image} style={{ maxHeight: "355px" ,maxWidth:"355px"}}></img>
            <h4 className='font-weight-bold'>Tap "Open"</h4>
            <h4 className='font-weight-bold'>To Access Catalog</h4>
        </div>
      </div>
    </>
  )
}

export default Access
