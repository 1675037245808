import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Catalouge from './Catalouge';
import SignIn from '../Main/Auth/signIn';
import Access from '../Main/Auth/access'
import RegisterUser from '../Main/Auth/registerUser';
import PrivateRouteDashboard from './privateRouteDashboard';
import ForgotPassword from '../Main/Auth/forgotPassword';
import ResetPassword from '../Main/Auth/resetPassword';
function Routes() {
  const isAuth = Boolean(localStorage.getItem("user"))  
  return (
    <Router>
      <Switch>
        {isAuth ? <Route exact path="/" component={Catalouge} /> : <Route exact path="/sign-in" component={SignIn} />}
        <Route exact path="/b" component={Access} />
        <Route exact path="/b/:id" component={Access} />
        <Route exact path="/register" component={RegisterUser} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path='/resetPassword/:token' component={ResetPassword} />
        <Catalouge/>
      </Switch>
    </Router>

  )
}

export default Routes
