import React from 'react';
import {Redirect,Route} from 'react-router-dom';




const PrivateRouteDashboard = ({ component: Component, ...rest }) => {
    return (
        <Route
        {...rest}
         render={(props)=>(
             localStorage.getItem('user') ? <Component {...props}{...rest} ></Component>:<Redirect to={{
                 pathname:'/',
                 state:{login:true,newUser:false}
             }}></Redirect>
         )}
        />
    )
}

export default PrivateRouteDashboard;
