import React from "react";
import { FieldArray, Field } from "formik";

function Storehours({op_hours}) {
  return (
    <div className="card_wrap mb-4  animate__animated animate__fadeIn  ">
      <div className="card_head">
        <span className="items_text text_dark">Store Hours</span>
      </div>
      <div className="card_body_1 input_w_77 sm_overflow">
        <div className="mx_width">
        <FieldArray name="op_hours" render={arrayHelpers=>(
          <div>
            {op_hours.length>0&&op_hours.map((info,index)=>{
              return (
                <div className="edit-item d-flex align-items-center mb-4"  key={index}
                >
                    
                  <div className="left_edit">
                    <label
                      className="mb-0 items_text"
                      htmlFor={info.type}
                    >
                      {info.type}
                    </label>
                  </div>
                  <div className="right_edit w-100 d-flex align-items-center">
                    <div className="d-flex">
                      <Field
                        type="time"
                        name={`op_hours.${index}.start`}
                    
                        className={`form-control    items_text cs_input border_1 focus_out mr-3`}
                        placeholder="00:00"
                      />
                      <Field
                        type="time"
                        name={`op_hours.${index}.end`}
                        
                        className="form-control items_text cs_input border_1 focus_out"
                        placeholder="00:00"
                      />
                    </div>
                    <div className="seprate mr-1 ml-1">
                      <i className="bi bi-dash"></i>
                    </div>
                    <div className="d-flex">

                      <Field type="time" name={`op_hours.${index}.start2`}  className="form-control items_text cs_input border_1 focus_out mr-3" placeholder="00:00" />
                      <Field type="time"  name={`op_hours.${index}.end2`} className="form-control items_text cs_input border_1 focus_out" placeholder="00:00" />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}>
        </FieldArray>
        </div>
      </div>
    </div>
  );
}

export default Storehours;
