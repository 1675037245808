import React from 'react';

export default class Setting extends React.Component {
    render() {
      return(
              <>
                <div className="main_head d-flex sm_col_flex">
                  <p className="title m-0">Edit Offer</p>
                  <div className="btn-group ml-auto sm_btn_group" role="group" aria-label="Basic example">
                    <button type="button" className="cs_btn out_line1 ml-0 ml-5 btn_focus"><i className="mr-1 bi bi-trash"></i> Delete</button>
                    <button type="button" className="cs_btn out_line2 btn_focus">Cancel</button>
                    <button type="button" className="cs_btn bg_prime border-0 btn_focus">Save Changes</button>
                  </div>
                </div>
                <div className="card_wrap">
                  <div className="card_body_1">
                  <div className="edit-item d-flex align-items-center mb-4">
                    <div className="left_edit">
                      <label className="mb-0 items_text">Offer Description</label>
                    </div>
                    <div className="right_edit w-100">
                      <input type="text" className="form-control items_text cs_input border_1 focus_out" autoComplete="true" placeholder="Eg. 30% off on all items" />
                    </div>
                  </div>
                  </div>
                </div>
              </>
      )
    }
  }



