import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import mainLogo from '../../Assets/image/mainLogo.png';
import { useFormik } from 'formik';
import { useState } from 'react';
import Spinner from '../Components/Spinner';
import * as Yup from 'yup';
import { LoginAPI } from '../../Api/api';
import { useHistory, useLocation } from "react-router-dom";
import Alert from '../Components/alert';
import { useEffect } from 'react';
import { uniqid } from 'uniqid';
import { useParams } from 'react-router';
import { Redirect } from 'react-router';
import { baseUrl } from '../../Api/baseUrl';
import axios from 'axios';

const SignupSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string().required('Required'),
    confirmPassword:Yup.string().oneOf([Yup.ref('password')],'Mismatched Passwords').required('Required Feild')
});
  

const ResetPassword = () => {

  const history = useHistory()
  const params = useParams()
  const [pass, setPass] = useState(false)
  const [loading, setLoading] = useState(false)
    const [message, setmessage] = useState({
        type: '',
        message:''
    })

    const formik = useFormik({
        initialValues: {
          password: '',
          confirmPassword: ''
        },
        onSubmit: values => {
          setLoading(true)
          console.log(values);
        },
        validationSchema: SignupSchema
    
    })
    
    function handlePass() {
        if(pass) setPass(false)
        else setPass(true)
    }
  
  const handleSubmit = (password) => {

    let passwordValidation = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    
    if (passwordValidation.test(formik.values.password)) {

          setmessage({type : '',message : ''})

          let token = params.token
          var data = JSON.stringify({"newPassword":`${password}` ,"token":`${token}`});

          var config = {
            method: 'post',
            url: `${baseUrl}/password`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios(config)
          .then(function (response) {
          console.log(JSON.stringify(response.data));
            // history.push('/')
            setTimeout(() => {
              history.push('/')
            }, 3000);
            setmessage({ type: 'alert-success', message: 'Password Reset Successfully. You can login now' })
          })
          .catch(function (error) {
          console.log(error);
          });
      
    } else {
      setmessage({ type: 'alert-danger', message: 'Password must have 8 character long and must contain at least one uppercase character one lowercase character one number and one special character' })      
    }


  }
  

    
    // const router = useRouter
    // console.log(router)

    return (
        <div>
            
      <template>
        <header
          id="header"
          className="u-header u-header--abs-top-md u-header--bg-transparent u-header--show-hide-md"
        >
          <div className="u-header__section py-2">
            <div id="logoAndNav" className="container">
              {/* Nav */}
              <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                {/* Logo */}
                <Link
                  className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                >
                  <img alt="" className="brand-logo" />
                </Link>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  onClick
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
                {/* End Responsive Toggle Button */} {/* Navigation */}
                <div
                  id="navBar"
                  className="collapse navbar-collapse u-header__navbar-collapse"
                  style={{ "display": "unset" }}
                >
                  <div className="js-scroll-nav navbar-nav align-items-md-center u-header__navbar-nav">
                    <Link to="/register" className="nav-item u-header__nav-item">
                      <button className="btn btn-sm btn-primary transition-3d-hover">
                        Sign up
                      </button>
                    </Link>

                  </div>
                </div>

              </nav>

            </div>
          </div>
        </header>
            </template>
            
      {/* Header Section */}
      <div className="container">
        <header className="header_section d-flex padding_tb_20 align-items-center">
          <div className="logo_section border-0">
            <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="w-75 img-fluid" />
          </div>
          <div className="log_button_section  border-0 ml-auto">
            <Link Link to="/register" className="btn btn-primary log_btn">Sign up</Link>
          </div>
        </header>
      </div>

      {/* End */}



      {/* ========== END HEADER ========== */}
      {/* ========== MAIN CONTENT ========== */}
        
      <div className="d-flex justify-content-center">
            <Alert type={` animate__animated animate__fadeIn  ${message.type}`} message={message.message}></Alert>
      </div>
            <div className="center_container_box">
                <div className="inner_width_center">
                    <form className="js-validate sign-in-form form-placeholder" >
                        <div className="mb-7 text-center ">
                            <h1 className="font-size-md-down-3 font-weight-bold mb-3">
                                Reset Password
                            </h1>
                        </div>
                        <div className="js-form-message form-group">
                            <div className='position-relative'>
                                <input
                                    type={pass ? 'text' : 'password'}
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    className={`input_field focus_out ${formik.touched.password && formik.errors.password ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                                    placeholder={formik.touched.password && formik.errors.password ? formik.errors.password : 'Enter Password'}
                                />
                            <i className={pass ?'bi bi-eye-slash eye_icon':'bi bi-eye-fill eye_icon'} onClick={handlePass}></i>
                            </div>
                        </div>
                        <div className="js-form-message form-group">
                            <input
                                type="password"
                                name='confirmPassword'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                                className={`input_field focus_out ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                                placeholder={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : 'Enter Confirm Password'}
                            />
                        </div>
                        <div className="row align-items-center mb-1 space-bottom-3 space-bottom-lg-0">
                        <div className='col-12 text-center mt-3 position-relative'>
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-wide log_btn round_border ex_space_btn'
                                    disabled={!formik.isValid || loading}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      handleSubmit(formik.values.password)
                                    }}
                                >
                                {loading ? <Spinner small={true} /> : 'Reset Password'}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
        </div>
        {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      {/* <footer>
        <div className="container">
          <div className="footer_section d-flex align-items-center">
            <div className="logo_section border-0 sm_mb_4">
              <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="img-fluid" />
            </div>
            <div className="footer_list_option ml-auto unset_ml_auto sm_mb_4">
              <ul className="m-0 p-0 d-flex">
                <li>about</li>
                <li>services</li>
                <li>our work</li>
              </ul>
            </div>
            <div className="footer_social_icons ml-auto unset_ml_auto">
              <ul className="m-0 p-0 d-flex">
                <li><i class="fab fa-facebook-f"></i></li>
                <li>
                  <i className="fab fa-google"></i>
                </li>
                <li>
                  <i className="fab fa-twitter"></i>
                </li>
                <li>
                  <i className="fab fa-github"></i>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </footer> */}
        
      </div>
    )
}

export default withRouter(ResetPassword)