export const svgDragFun = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M7.417 16.146q-.646 0-1.105-.458-.458-.459-.458-1.126 0-.645.458-1.104Q6.771 13 7.438 13q.645 0 
    1.104.458.458.459.458 1.125 0 .646-.458 1.105-.459.458-1.125.458Zm5.145 0q-.645 0-1.104-.458Q11 15.229 11 14.562q0-.645.469-1.104.469-.458 1.114-.458.646 0 1.105.458.458.459.458 
    1.125 0 .646-.458 1.105-.459.458-1.126.458Zm-5.145-4.563q-.646 0-1.105-.468-.458-.469-.458-1.115 0-.667.458-1.125.459-.458 1.126-.458.645 0 1.104.468Q9 9.354 9 
    10t-.458 1.115q-.459.468-1.125.468Zm5.145 0q-.645 0-1.104-.468Q11 10.646 11 10q0-.667.469-1.125.469-.458 1.114-.458.646 0 1.105.468.458.469.458 1.115t-.458 
    1.115q-.459.468-1.126.468ZM7.417 7q-.646 0-1.105-.469-.458-.469-.458-1.114 0-.646.458-1.104.459-.459 1.126-.459.645 0 1.104.459Q9 4.771 9 5.438q0 .645-.458 1.104Q8.083 7 
    7.417 7Zm5.145 0q-.645 0-1.104-.469Q11 6.062 11 5.417q0-.646.469-1.104.469-.459 1.114-.459.646 0 1.105.459.458.458.458 1.125 0 .645-.458 1.104Q13.229 7 12.562 7Z"/></svg>
}

export const svgFadeEyeFun1 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M10 12.896q1.396 0 
    2.365-.969.968-.969.968-2.365 0-1.395-.968-2.364-.969-.969-2.365-.969-1.396 0-2.365.969-.968.969-.968 2.364 0 1.396.968 2.365.969.969 2.365.969Zm0-1.125q-.917 0-1.562-.646-.646-.646-.646-1.563 
    0-.916.646-1.562.645-.646 1.562-.646T11.562 8q.646.646.646 1.562 0 .917-.646 1.563-.645.646-1.562.646Zm0 3.521q-2.833 0-5.135-1.563-2.303-1.562-3.407-4.167 1.125-2.583 
    3.427-4.135Q7.188 3.875 10 3.875q2.812 0 5.115 1.552 2.302 1.552 3.427 4.135-1.104 2.605-3.407 4.167-2.302 1.563-5.135 1.563Zm0-5.73Zm0 4.48q2.292 0 4.229-1.209 1.938-1.208 
    2.938-3.271-1-2.062-2.938-3.25Q12.292 5.125 10 5.125T5.76 6.312Q3.812 7.5 2.812 9.562q1 2.063 2.938 3.271 1.938 1.209 4.25 1.209Z"/></svg>
}

export const svgFillEyeFun2 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m16.396 18.854-3.458-3.458q-.73.229-1.459.333-.729.104-1.479.104-3.167 0-5.625-1.739-2.458-1.74-3.542-4.532.417-1.083 
    1.084-2.01.666-.927 1.5-1.677L1.125 3.562l1.25-1.208 15.25 15.271ZM10 13.354q.208 0 .417-.021.208-.021.395-.062l-4.5-4.5q-.041.208-.072.396-.032.187-.032.395 0 1.584 1.104 2.688Q8.417 13.354 10 
    13.354Zm6.167.292-2.646-2.625q.125-.292.198-.667.073-.375.073-.792 0-1.583-1.104-2.687Q11.583 5.771 10 5.771q-.375 0-.74.073-.364.073-.718.198L6.5 4q.854-.354 1.708-.51.854-.157 1.792-.157 
    3.146 0 5.615 1.719 2.468 1.719 3.552 4.51-.479 1.23-1.25 2.261-.771 1.031-1.75 1.823ZM12.25 9.75 9.812 7.312q.542-.041 1.011.146.469.188.781.542.354.396.521.833.167.438.125.917Z"/></svg>
}

export const svgArrowFun = (transitionDuration, isOpen) => {
    return <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transitionDuration, transform: isOpen ? "rotate(-90deg)" : "" }}><path d="M0.745899 
    15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655582 0.66461 0.333375L0.745899 0.241064C1.04396 -0.051136 1.51037 
    -0.0776978 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"fill="black" fillOpacity="0.6" /></svg>
}

export const confirmBoxFunc = (toggelText) => {
    if(toggelText){
        return true;
    }else{
        return window.confirm(`Are you sure you want to hide your selection from your catalog?`);
    }
}
