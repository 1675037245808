/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState, useLayoutEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Spinner from "../Components/Spinner";
import CreatableSelect from "react-select/creatable";
import {
	FetchAPI,
	FetchAPILocal,
	postAPI,
	uploadImage,
	deleteImage,
	deleteAPI,
} from "../../Api/api";
import axios from "axios";
import { useRef } from "react";
import { validation } from "./utils";
import { toast } from "react-toastify";
import {
	create_subcat,
	create_cat,
	contains_dollar,
	sanitizeData,
} from "../../Utils/utils";
import { prototype, initialValues2 as initialValues, Santize } from "./utils";
import { update } from "../../Api/api";

export default function Edititem() {
	//category states for async
	const [category, setCategory] = useState([]);
	const [subCategory, setSubcategory] = useState([]);

	const [disabled, setDisabled] = useState(false);

	//loader states
	const [buttonLoader, setButtonLoader] = useState(false);
	const [mainLoad, setMainLoad] = useState(true);
	const [miniLoader, setMiniLoader] = useState(false);
	const [catLoad, setCatLoad] = useState(false);
	const [videoMiniLoader, setvideoMiniLoader] = useState(false);

	const localInfo = useRef(null);
	const [video, containsVideo] = useState(false);

	const [videoInfo, setVideoInfo] = useState([]);

	//refs fore the newest category to be created
	const isNewCat = useRef(null);
	const isNewSubcat = useRef(null);

	let id = useParams();
	let history = useHistory();

	useEffect(() => {
		//assigns token from local storage (Bad practice)
		localInfo.current = JSON.parse(localStorage.getItem("user"));

		fetchInformation(localInfo.current.token);
		FetchCategories(localInfo.current.twbusiness, localInfo.current.token);

		return () => {
			setCategory([]);
			setSubcategory([]);
		};
	}, []);

	//fetches the information about the catelog which needs to be chnaged.
	function fetchInformation(token) {
		FetchAPI(`/catalogues/${id.catalogID}`, token)
			.then(async (response) => {
				//constructs an object favorable for formik
				Santize(response.data);
				//can it be crashed over here.
				let cat = response.data;
				return FetchAPI(
					`/catalog-categories?searchType=2&id=${cat.category._id}&_sort=createdAt:desc`,
					localInfo.current.token
				);
			})
			.then((result) => {
				if (Array.isArray(result.data) && result.data.length > 0)
					setSubcategory(result.data);
			})
			.catch((error) => {
				console.log(error.response ? error.response : error.message);
			})
			.then(() => {
				setMainLoad(false);
			});
	}

	//handles the form chages and submits the form.
	const handleSubmit = async (value) => {
		setButtonLoader(true);
		if (video) {
			let videoInfo = await uploadVideo();

			//a type of then.
			if (videoInfo != null) {
				console.log(videoInfo);
				value.image_url = videoInfo.data[0].url;
				value.is_video = true;
				value.photoId = videoInfo.data[0].id;
			}
		}

		if (isNewCat.current) {
			let data = await create_cat(isNewCat.current);
			console.log(data);
			value.category = data;
		}

		if (isNewSubcat.current) {
			let data = await create_subcat({
				name: isNewSubcat.current.name,
				parent_id: value.category.value,
			});

			console.log(data);
			value.subcategory = data;
			console.log(value.subCategory);
		}

		value.price = contains_dollar(value.price);
		let bodyData = sanitizeData(value);
		console.log(bodyData);

		let catalog = id.catalogID;

		update(`/catalogues/${catalog}`, bodyData)
			.then((result) => {
				toast.info("Item updated Successfully", {
					autoClose: 2000,
				});

				setButtonLoader(false);
				history.push("/");
			})
			.catch((err) => {
				console.log(err);
				toast.info("Could not save changes", {
					autoClose: 2000,
				});
				setButtonLoader(false);
			});
		// };
	};

	//uploads the video and returns  info from the object.
	function uploadVideo() {
		const formData = new FormData();
		formData.append("files", videoInfo[videoInfo.length - 1]);
		return uploadImage(`/upload`, formData);
	}

	//every business has a Category coming from the cateloh categories table
	function FetchCategories(bussID, token) {
		setCatLoad(true);

		FetchAPI(
			`/catalog-categories?searchType=1&id=${bussID}&_sort=createdAt:desc`,
			token
		)
			.then((res) => {
				setCategory(res.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.then((result) => {
				setCatLoad(false);
			});
	}

	//sets the formik value if a new category is created.
	const handleCatChange = async (newValue, formik) => {
		try {
			if (newValue) {
				if (newValue.__isNew__) {
					isNewCat.current = {
						name: newValue.value,
						twbusiness: localInfo.current.twbusiness,
					};

					formik.setFieldValue(
						`category`,
						prototype(newValue.label, newValue.value),
						false
					);

					formik.setFieldValue("subcategory", prototype(), false);

					setSubcategory([]);
					console.log(formik.values);
				} else {
					isNewCat.current = null;
					setCatLoad(true);
					let category = await FetchAPI(
						`/catalog-categories?searchType=2&id=${newValue.value}&_sort=createdAt:desc`,
						localInfo.current.token
					);

					//set subcategory is meisleading, change this to a different name;
					if (category.data) setSubcategory(category.data);

					let prot = prototype(newValue.label, newValue.value);

					formik.setFieldValue(
						"category",
						prototype(newValue.label, newValue.value)
					);

					formik.setFieldValue("subcategory", prototype(), false);
				}
			} else {
				isNewCat.current = null;
				formik.setFieldValue("category", prototype());
				formik.setFieldValue("subcategory", prototype(), false);
			}

			setCatLoad(false);
		} catch (error) {
			setCatLoad(false);
			console.log(error);
		}
	};

	//handles the sub catelog changes and creates new if user wants it.
	const handleSubCatChange = async (newValue, formik) => {
		try {
			//! what happpens when I delete all of it.
			if (newValue) {
				if (newValue.__isNew__) {
					isNewSubcat.current = {
						name: newValue.value,
						parent_id: formik.values.category.value,
					};

					//this is a new data we need to create it first.
					formik.setFieldValue(
						"subcategory",
						prototype(newValue.label, newValue.value),
						false
					);
				} else {
					isNewSubcat.current = null;

					formik.setFieldValue(
						"subcategory",
						prototype(newValue.label, newValue.value)
					);
				}
			} else {
				formik.setFieldValue("subcategory", prototype(), false);
			}

			setCatLoad(false);
		} catch (err) {
			console.log(err);
			setCatLoad(false);
		}
	};

	//uploads the file and sets the data using formik
	async function fileUpload(e, formik) {
		let lastIndex = e.target.files.length - 1;
		if (e.target.files[lastIndex].type.includes("video")) {
			containsVideo(true);
			//saves video info in the state which is called on Handle Submit
			const file = e.target.files[e.target.files.length - 1];
			const newFile = new File([file], file.name + ".mp4", {
				type: file.type,
				lastModified: file.lastModified,
			});
			setVideoInfo([newFile]);

			// -------getting video height width----------
			const formData = new FormData();
			formData.append("files", e.target.files[e.target.files.length - 1]);

			const url = URL.createObjectURL(newFile);
			const video = document.createElement("video");
			video.onloadedmetadata = () => {
				URL.revokeObjectURL(url);
				const isPortrait = video.videoWidth >= video.videoHeight ? false : true;
				formik.setFieldValue("isPortrait", isPortrait);
				console.log(
					video.videoWidth + "X" + video.videoHeight,
					", IsPortrait:",
					isPortrait,
					"dflksjdflksjdflksjdflksjdflkjkdjfskdjflksjd"
				);
			};
			video.src = url;
			video.load();
			// ----------------------------------------
			setvideoMiniLoader(true);
			uploadImage(`/upload`, formData)
				.then((result) => {
					console.log(result);
					formik.setFieldValue("thumbnail", "");
					setTimeout(() => {
						formik.setFieldValue("image_url", result.data[0].url);
						formik.setFieldValue("is_video", true);
						formik.setFieldValue("photoId", result.data[0].id);
						formik.setFieldValue("thumbnail", result.data[0].hash);
						setvideoMiniLoader(false);
					}, 5000);
					console.log("insied function");
				})
				.catch((err) => {
					console.log(err);
				})
				.then((result) => {
					setMiniLoader(false);
					document.getElementById("up_file").value = [];
				});
			toast.info("Your Video will be uploaded after you save the changes", {
				autoClose: 3000,
			});
		} else if (
			e.target.files[e.target.files.length - 1].type.includes("image")
		) {
			if (e?.target?.files[0]?.type.includes("webp")) {
				toast.error(`webp files are not supported.`, {
					position: toast.POSITION.TOP_CENTER,
				});
			} else {
				setMiniLoader(true);
				if (
					formik.values.photoId.length > 0 &&
					formik.values.image_url.length > 0
				) {
					await deleteImage(`/upload/files/${formik.values.photoId}`).catch(
						(err) => {
							console.log("error in deleting", err);
						}
					);
				}
				if (video) {
					containsVideo(false);
					setVideoInfo([]);
				}
				const formData = new FormData();
				formData.append("files", e.target.files[e.target.files.length - 1]);

				//----- getting image height width--------
				const img = new Image();
				var objectUrl = URL.createObjectURL(
					e.target.files[e.target.files.length - 1]
				);
				img.onload = function () {
					const isPortrait = this.width >= this.height ? false : true;
					formik.setFieldValue("isPortrait", isPortrait);
					console.log(
						this.width + "X" + this.height,
						", IsPortrait:",
						isPortrait
					);
				};
				img.src = objectUrl;
				//-----------------------------------------

				setvideoMiniLoader(true);
				uploadImage(`/upload`, formData)
					.then((result) => {
						console.log(result);

						formik.setFieldValue("image_url", result.data[0].url);
						formik.setFieldValue("is_video", false);
						formik.setFieldValue("photoId", result.data[0].id);
					})
					.catch((err) => {
						console.log(err);
					})
					.then((result) => {
						setMiniLoader(false);
						setvideoMiniLoader(false);
						document.getElementById("up_file").value = [];
					});
			}
		} else {
			let type = e.target.files[lastIndex].type.split("/");
			toast.error(`${type[1]} files are not suported `, {
				position: toast.POSITION.TOP_CENTER,
			});
		}
	}

	const uploadThumbnail = (e, formik) => {
		const image = e.target.files && e.target.files.length && e.target.files[0];
		if (image && image.type.includes("image")) {
			if (e?.target?.files[0]?.type.includes("webp")) {
				toast.error(`webp files are not supported.`, {
					position: toast.POSITION.TOP_CENTER,
				});
			} else {
				const formData = new FormData();
				//----- getting image height width--------
				const img = new Image();
				var objectUrl = URL.createObjectURL(image);
				img.onload = function () {
					const isThumbnailPortrait = this.width >= this.height ? false : true;
					formik.setFieldValue("isThumbnailPortrait", isThumbnailPortrait);
					console.log(
						this.width + "X" + this.height,
						", IsPortrait:",
						isThumbnailPortrait
					);
				};
				img.src = objectUrl;
				//-----------------------------------------

				formData.append("files", image);
				uploadImage(`/upload`, formData)
					.then((result) => {
						formik.setFieldValue("videoThumbnail", result.data[0].url);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			toast.error(`Please select image`, {
				position: toast.POSITION.TOP_CENTER,
			});
		}
	};

	//deltes the file and performs tasks accordingly.
	function deleteFile(formik) {
		setMiniLoader(true);
		deleteImage(`/upload/files/${formik.values.photoId}`)
			.then((result) => {})
			.catch((err) => {
				console.log(err);
			})
			.then((res) => {
				formik.setFieldValue("image_url", "");
				formik.setFieldValue("photoId", "");
				formik.setFieldValue("is_video", false);
				setMiniLoader(false);
			});
	}

	function cancelHandler() {
		history.goBack();
	}

	//! this should move in the utils folder, this is repeating too many times.
	function extractThumbnail(awsUrl) {
		let splitArray = awsUrl.split(".");

		return splitArray[3].substr(4);
	}

	return (
		<div>
			{mainLoad ? (
				<div className="d-flex  justify-content-center mt-4">
					<Spinner />
				</div>
			) : (
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validation}
					enableReinitialize
				>
					{(formik) => (
						<Form>
							<div className="main_head d-flex">
								<p className="title m-0">Edit Item</p>
								<div
									className="btn-group ml-auto"
									role="group"
									aria-label="Basic example"
								>
									<button
										type="button"
										onClick={() => cancelHandler()}
										className="cs_btn out_line2 btn_focus"
									>
										Cancel
									</button>
									<button
										type="submit"
										className="cs_btn bg_prime border-0 btn_focus"
										disabled={!formik.values.photoId.length > 0 || buttonLoader}
									>
										{buttonLoader ? <Spinner small={true} /> : "Save Changes"}
									</button>
								</div>
							</div>
							{console.log(
								formik.values,
								"1111111111111111111111111111111111s"
							)}
							<div className="card_container mb-5 card_edit animate__animated animate__fadeIn">
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Item Image</label>
									</div>
									<div className="right_edit d-flex align-items-center">
										<div className="items_images position-relative bg_light">
											<div className="items_images">
												<img
													src={
														formik.values.is_video
															? `https://stream.locol.partners/${formik.values.thumbnail}/thumb/${formik.values.thumbnail}.jpg`
															: formik.values.image_url
															? formik.values.image_url
															: require("../../Assets/image/blank.png").default
													}
													className=" position-relative "
													style={{
														objectFit: "cover",
														width: "100%",
														height: "100%",
														borderRadius: "15px",
													}}
												></img>

												<button
													htmlFor="up_file"
													type="button"
													className="edit_btn focus_out over_flow_hidden"
												>
													<label
														htmlFor="up_file"
														className="bi bi-pencil-fill mb-0"
													></label>
													<input
														accept="image"
														type="file"
														onChange={(e) => fileUpload(e, formik)}
														className="upload_input"
														id="up_file"
													></input>
												</button>
											</div>
										</div>
										<button
											type="button"
											className="cs_btn out_line1 ml-0 ml-5"
											disabled={
												formik.values.photoId.length
													? videoMiniLoader
														? true
														: false
													: true
											}
											onClick={() => deleteFile(formik)}
										>
											<i className="mr-1 bi bi-trash"></i>
											{miniLoader || videoMiniLoader ? (
												<Spinner small={true} />
											) : (
												"Delete"
											)}
										</button>
									</div>
								</div>

								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">
											Item thumbnail(optional)
										</label>
									</div>
									<div className="right_edit d-flex align-items-center">
										<div className="items_images position-relative bg_light">
											<div className="items_images position-relative bg_light">
												<img
													src={
														formik.values.videoThumbnail
															? formik.values.videoThumbnail
															: `https://stream.locol.partners/${formik.values.thumbnail}/thumb/${formik.values.thumbnail}.jpg`
													}
													className="items_thumbnail_images position-relative "
													style={{
														objectFit: "cover",
														width: "100%",
														height: "100%",
														borderRadius: "15px",
													}}
												/>
												<button
													htmlFor="up_thumbnail_file"
													type="button"
													className="edit_btn focus_out over_flow_hidden"
												>
													<label
														htmlFor="up_thumbnail_file"
														className="bi bi-pencil-fill mb-0"
													></label>
													<input
														accept="image"
														type="file"
														onChange={(e) => uploadThumbnail(e, formik)}
														className="upload_input"
														id="up_thumbnail_file"
													></input>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Item name</label>
									</div>
									<div className="right_edit w-100">
										<Field
											id="name"
											name="name"
											type="text"
											className={`form-control ${
												formik.touched.name && formik.errors.name
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Enter Item name"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Description</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											id="description"
											name="description"
											className={`form-control ${
												formik.touched.description && formik.errors.description
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Enter description"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Category</label>
									</div>
									<div className="right_edit w-100">
										<CreatableSelect
											id="category"
											name="category"
											isDisabled={catLoad}
											value={formik.values.category}
											isClearable
											onChange={(newValue, action) =>
												handleCatChange(newValue, formik, action)
											}
											isLoading={catLoad}
											className={`${
												formik.touched.category && formik.errors.category
													? "border border-danger"
													: ""
											}`}
											options={category}
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Sub-category</label>
									</div>
									<div className="right_edit w-100">
										<CreatableSelect
											id="subcategory"
											name="subcategory"
											value={formik.values.subcategory}
											isDisabled={catLoad}
											isClearable
											onChange={(newValue) =>
												handleSubCatChange(newValue, formik)
											}
											isLoading={catLoad}
											className={`${
												formik.touched.subcategory && formik.errors.subcategory
													? "border border-danger"
													: ""
											}`}
											options={subCategory}
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Price</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											name="price"
											id="price"
											className={`form-control ${
												formik.touched.price && formik.errors.price
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="$1.35"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">CTA</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											id="action"
											name="action"
											className={`form-control ${
												formik.touched.action && formik.errors.action
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Select call to action"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Link</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											id="forwardLink"
											name="forwardLink"
											className={`form-control ${
												formik.touched.forwardLink && formik.errors.forwardLink
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="www.htmlstream.com"
										/>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
}
