import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {checkValidity} from '../Utils/utils';
var QRCode = require("qrcode.react");


class sidebar extends React.Component {

 state={
    showCode:false,
    businessID:null,
    placeId: null,
    checkCopy: false,
    appClipUid:1
 }



 async componentDidMount(){
 try {
  let status=await checkValidity();

  if(status.accepted){
        this.setState({businessID:status.twbusiness})
    this.setState({placeId: status.placeId})
    this.setState({showCode:true,appClipUid:status?.appClipUid})
  }    
 } catch (error) {
   console.log(error)
 }


 }


  download = () => {
    try {
      
      const canvas = document.getElementById("123456");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "QRCode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    
    } catch (error) {

      console.log(error);
      toast.error(`Could not perform this action`, { autoClose: 2000 });
    }
  };

  logout = () => {
    localStorage.removeItem("user");
    this.props.history.push({
      pathname: "/sign-in",
      state: { newUser: false, login: false },
    });
  };

  handleCopy = (placeId, businessId) => {
    navigator.clipboard.writeText(`https://locol.partners/b/${this.props.scanUrl}?I=${placeId}&I2=${businessId}`)
    this.setState({ checkCopy: true }, () => {
      if (this.state.checkCopy) {
        setTimeout(() => {
          this.setState({checkCopy: false})
        },1000)
      }
    })
  
  }

  render() {

    return (
      <>
        <div className="sidebar_header">
          <div className="d-flex align-items-center ">
            <img
              src={require("../Assets/image/locol-01.b282249b.png").default}
              className="logo_size"
              alt="okur"
            />
            <span className="sub_title">BUSINESS</span>
          </div>
        </div>
        <div className="sidebar_menu">
          <menu>
            <li>
              <NavLink
                to="/"
                exact
                activeClassName="active_items"
                className={`items d-flex ${
                  this.props.location.pathname == "/add-item"
                    ? "active_items"
                    : ""
                } align-items-center`}
              >
                <img
                  src={require("../Assets/image/catalog_active.png").default}
                  alt="catalog"
                  className="im_active"
                />
                <img
                  src={require("../Assets/image/catalog.png").default}
                  alt="catalog"
                  className="in_active"
                />
                My catalog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/my-offers"
                exact
                activeClassName="active_items"
                className="items d-flex align-items-center"
              >
                <img
                  src={require("../Assets/image/offers_active.png").default}
                  alt="catalog"
                  className="im_active"
                />
                <img
                  src={require("../Assets/image/offers.png").default}
                  alt="catalog"
                  className="in_active"
                />
                My live offers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/change-order"
                exact
                activeClassName="active_items"
                className="items d-flex align-items-center"
              >
                <img
                  src={require("../Assets/image/storefront_active.png").default}
                  alt="catalog"
                  className="im_active"
                />
                <img
                  over="blue"
                  src={require("../Assets/image/storefront.png").default}
                  alt="catalog"
                  className="in_active"
                />
                Store Front
              </NavLink>
            </li>
            <li className="mb-0">
              <NavLink
                to="/business-profile"
                exact
                activeClassName="active_items"
                className="items d-flex align-items-center"
              >
                <img
                  src={require("../Assets/image/briefcase.png").default}
                  alt="catalog"
                />
                Business profile
              </NavLink>
            </li>
          </menu>
        {this.state.showCode&& <div className="qr_section text-center qr-settings">
            <QRCode
              id="123456"
              value={`https://locol.partners/b/${this.props.scanUrl}?I=${this.state.placeId}&I2=${this.state.businessID}`}
              size={100}
              level={"M"}
              includeMargin={false}
            />
            {/* <img src={require('../Assets/image/qr_code.png').default} alt="qr code" className="img-fluid"/> */}
            <p style={{ cursor: "pointer",marginBottom:0 }} onClick={() => this.download()}>
              Print QR Code
            </p>
            {this.state.checkCopy ?
              <p style={{ cursor: "pointer",color:"#000",fontSize: "12px" }}><i className="fas fa-check" /> Copied</p>
              :
              <p data-toggle="tooltip" data-placement="top" title="copy to clipboard" onClick={() => this.handleCopy(this.state.placeId, this.state.businessID)} style={{ cursor: "pointer",color:"#000",fontSize: "12px" }}>Copy QR Link</p>
            }
          </div>}
        

          {/* Setting */}
          <footer className="sidebar_footer">
            <menu>
              <li>
                <NavLink
                  to="/setting"
                  exact
                  activeClassName="active_items"
                  className="items d-flex align-items-center"
                >
                  <i className="bi bi-gear"></i>
                  Settings
                </NavLink>
              </li>
              <li className="mb-0 ml-4">
                <div className="pd_left_right d-flex">
                  <i className="bi bi-box-arrow-right"></i>

                  <p style={{ cursor: "pointer" }} onClick={this.logout}>
                    logout
                  </p>
                </div>
              </li>
            </menu>
          </footer>
          {/* End */}
        </div>
      </>
    );
  }
}

export default withRouter(sidebar);
