import React from 'react'

const ChangeStoreDescription = () => {
  return (
  <>
    <p style={{ fontSize:"18px"}}>
        Your categories and subcategories are displayed on your store front following the below order. Drag and drop categories to change their order, once updated your order will be reflected in your live store. Scan QR code to check.
    </p>
    <hr />
  </>
  )
}

export default ChangeStoreDescription;