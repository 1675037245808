 export   let initialValues = {
    name: '',
    buss_category: '',
    about: '',
    website: '',
    phone: '',
    Address: '',
    GoogleRating: '',
    Buss_subcategory:'',
     placeId: '',
    photos_url: '',
    city: '',
    photoId: '',
    state: '',
    country: '',
    lat_lng: '',
    reviewEnabled: true,
    isPrivateStore: false,
    email: '',
    applicationId: '',
    op_hours: [
      {
        type: "Monday",
        start: '',
        end: '',
        start2:'',
        end2:''
      },
      {
        type: "Tuesday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
  
      },
      {
        type: "Wedensday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
  
      },
      {
  
        type: "Thursday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Friday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Saturday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Sunday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      }
    ],
   user: '',
   appClipUid:1,
   hasPhysicalAddress:true
  }
  
  
 export  let savedValues = {
    name: '',
    buss_category: '',
    Buss_subcategory:'',
    about: '',
    website: '',
    phone: '',
    photos_url: '',
    Address: '',
    GoogleRating: '',
    placeId: '',
    photoId: '',
    city: '',
    state: '',
    country: '',
    lat_lng: '',
    email: '',
    applicationId: '',
    reviewEnabled: "",
    isPrivateStore:"",
    radius:0.0,
    op_hours: [
      {
        type: "Monday",
        start: '',
        end: '',
        start2:'',
        end2:''
      },
      {
        type: "Tuesday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
  
      },
      {
        type: "Wedensday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
  
      },
      {
  
        type: "Thursday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Friday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Saturday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      },
      {
  
        type: "Sunday",
        start: '',
        end: '',
        start2:'',
        end2:''
  
      }
    ],
   user: '',
   appClipUid:1,
   hasPhysicalAddress:true
  }
  