import React from "react";
import { Field } from "formik";

//a stateless component just for rendering and modularzation.

export default function Fields(props) {
  return (
    <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
      <div className="left_edit">
        <label className="mb-0 items_text">{props.label} </label>
      </div>
      <div className="right_edit w-100">
        <Field
          name={props.name}
          type={props.type}
          className={`form-control ${
            props.touched&&props.error
              ? "border border-danger"
              : ""
          } items_text cs_input border_1 focus_out`}
          autoComplete="true"
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}
