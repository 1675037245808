import React from 'react'
import MyStore from './MyCatalog/MyStore';
import MyCatalog from './MyCatalog/MyCatalog';
import { useEffect, useState } from 'react';
import { FetchAPI, FetchAPILocal } from '../Api/api';
import Spinner from './Components/Spinner';
import { ToastContainer } from 'react-toastify';


function Home() {

    const [state, setstate] = useState(null);
    const [catalouge, setCataloge] = useState([]);
    const [loading, setLoading] = useState(true)

    //the approval state consist of 3 cases. -1,0,1

    /*-1 :for Never Applied
      0 :Applied but not approved
      1: Approved.
      the approval states are also being passed a prop into the MyCatalog component which has conditiobnal rendering into it.
    */
    const [approved, setApproved] = useState(-1)

    useEffect(() => {

        let info = localStorage.getItem('user')
        let userInfo = JSON.parse(info)

        let bussID = userInfo.twbusiness;
        let token = userInfo.token;

        FetchAPI(`/temp-businesses/?user=${userInfo.userID}`, token).then(result => {

            //   console.log(result.data)


            if (result.data.length > 0 && result.data[0].isApproved) {

                setApproved(1)
                if (bussID != result.data[0].twbusiness.id) bussID = result.data[0].twbusiness.id
                // console.log(bussID,result.data[0].twbusiness.id)

                return FetchAPI(`/catalog-categories/allcategories?bussinessID=${bussID}`, token)

            } else if (result.data.length > 0 && !result.data[0].isApproved) setApproved(0)

        }).then(result => {
            if (result) {

                setCataloge(result.data)

            }
        }).catch(error => {
            console.log(error)
        }).then(() => {
            setLoading(false)
        })




        return () => {

        }
    }, [])




    function fetchCatalouge(bussID, token) {
        setLoading(true)
        FetchAPI(`/catalog-categories/allcategories?bussinessID=${bussID}`, token).then(result => {
            //    console.log(result)
            setCataloge(result.data)

        }).catch(error => {
            setLoading(false)
            console.log(error)
        }).then(result => {
            setLoading(false)
        })

    }







    return (

        <div className="mn-height">

            {loading ? <div className='d-flex justify-content-center mt-4' style={{ width: '100%' }}> <Spinner /> </div> : catalouge.length > 0 ?
                <MyStore fetch={fetchCatalouge} data={catalouge}></MyStore>
                : <MyCatalog status={approved} ></MyCatalog>}
        </div>
    )
}

export default Home
