import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { FetchAPI } from '../../Api/api';
import Spinner from '../Components/Spinner';

let messages=[{
  title: 'Open Your Store Now',
  message:'You are now minutes away from having your own Locol store. Enjoy!'
},{
  title: 'Application under review',
  message:'Your application to open Locol store is under review, we will send you an email once approved'
},{
  title:'Congratulations!',
  message:'Your application to open a Locol store has been accepted, tap on the button below to create your catalog'
}]



class MyCatalog extends React.Component {


  constructor() {
    super()
    this.state = {
      loader: false,
      isApproved:false
    }
  }



  checkStatus = () => {
   if(this.props.status<0){
     this.props.history.push('/business-profile')
   }else {
     this.props.history.push('/add-item')
   }
  }


  render() {
    return (
      <div className="center_box animate__animated animate__fadeIn ">
     
        <div className="wrapper_card text-center">
          <div className="img_box">
            <img src={require('../../Assets/image/img_1.png').default} className="img-fluid" alt="store_img" />
          </div>
          <h2 className="title"> {this.props.status==-1?messages[0].title:this.props.status==0?messages[1].title:messages[2].title} </h2>
          <p className="sub_title ">{this.props.status==-1?messages[0].message:this.props.status==0?messages[1].message:messages[2].message}</p>
          {this.props.status!==0&& <button onClick={this.checkStatus} className="btn btn-primary mt_17 font_14"> {this.state.loader ? <Spinner small={true}></Spinner> :this.props.status==1?'Create Catalog':'Create Your Store'}</button>}
    
        </div>
      </div>
    )
  }
}



export default withRouter(MyCatalog)