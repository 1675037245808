import React from 'react';
import { Link } from "react-router-dom";

export default class Addliveoffer extends React.Component {
    render() {
      return(<>
          <div className="main_head d-flex sm_col_flex">
            <p className="title m-0">Live Offers</p>
            <div className="btn-group ml-auto sm_btn_group" role="group" aria-label="Basic example">
              <button type="button" className="cs_btn out_line1 ml-0 btn_focus"><i className="mr-1 bi bi-trash"></i> Delete</button>
              <Link type="button" className="cs_btn out_line2 btn_focus" to="/edit-offer"><i className="mr-1 bi bi-pencil"></i> Edit</Link>
              <button type="button" className="cs_btn bg_prime border-0 btn_focus"><i className="mr-1 bi bi-plus-lg"></i> New Offer</button>
            </div>
          </div>
          <div className="card_container mb-5">
            <div className="card_header d-flex">
              <div className="form-check form-check-inline">
                <label className="wrap_checkbox">
                  <span className="check_label position-relative">Name</span>
                  <input className="cs_check_box" type="checkbox" />
                  <span className="check_mark"></span>
                </label>
              </div>
              <p className="check_label mb-0 ml-auto">STATUS</p>
            </div>
            <div className="card_body bg-white">
                <div className="wp_container mb_40 d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">Get $5 off coupon - Use code GET5 to avail the offer</span>
                        <input className="cs_check_box" type="checkbox" defaultChecked />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" defaultChecked />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="wp_container mb_40 d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">BUY 3 GET 1 FREE!</span>
                        <input className="cs_check_box" type="checkbox" />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" defaultChecked />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="wp_container mb_40 d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">10% off on weekdays for any ice cream. Offer valid till 31st Dec, 2020</span>
                        <input className="cs_check_box" type="checkbox" />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" defaultChecked />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="wp_container mb_40 d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">Get extra 30% off on purchase above $10.</span>
                        <input className="cs_check_box" type="checkbox" />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" defaultChecked />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="wp_container mb_40 d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">BUY 3 GET 1 FREE!</span>
                        <input className="cs_check_box" type="checkbox"  />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="wp_container d-flex">
                  <div className="form-check form-check-inline">
                      <label className="wrap_checkbox">
                        <span className="check_label_2 position-relative">Get $5 off coupon - Use code GET5 to avail the offer</span>
                        <input className="cs_check_box" type="checkbox"  />
                        <span className="check_mark"></span>
                      </label>
                  </div>
                  <div className="ml-auto">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
            </div>
          </div>
          </>
          
      )
    }
  }



