import { useState } from "react";
import ChangedStoreToggle2 from "./ChangeStoreToggle2";
import '../../Assets/css/layout.css';
import { confirmBoxFunc, svgArrowFun, svgDragFun, svgFadeEyeFun1, svgFillEyeFun2 } from "./SvgFuntions";

export default function CategoryListItem({ index, value, handleUpdate, handleChangeCatagory, handleToggleChange, handleChangeitem }) {
    const [isOpen, setIsOpen] = useState(false)
    const categoryEyeFunc = (value, index) => {
        let whatVar;
        value.catalog_categories.every(items => items.items.every(values => values.isActive == false)) ? whatVar = true : whatVar = false;
        value.catalog_categories.map(items => items.items.map(values => values.isActive = whatVar));
        // handleUpdate();
        handleToggleChange("category", index);
    }

    let categoryLength = 100;
    if (window.innerWidth <= 400) {
        categoryLength = 7;
    } else if (window.innerWidth <= 500) {
        categoryLength = 9;
    } else if (window.innerWidth <= 600) {
        categoryLength = 12;
    }

    return (
        <div className="wr-p">
            <div key={index} data-toggle="collapse" style={{ padding: "0.7rem 0" }} className="main-outer bg-gray-color" data-target={`#yoo2${index}`} onClick={() => {
                setIsOpen(!isOpen);
            }}>
                <span style={{ paddingRight: "5px" }} >
                    {svgArrowFun("300ms", isOpen)}
                </span>{value?.name && value?.name?.length > categoryLength ? value?.name?.slice(0, categoryLength) + "..." : value?.name}
                <a style={{ float: "right" }}><span onClick={(e) => {
                    e.stopPropagation();
                    if (confirmBoxFunc(value.catalog_categories.every(items => items.items.every(values => values.isActive == false)))) {
                        categoryEyeFunc(value, index)
                    }
                }}>
                    {/* handleToggleChange("category", index) */}
                    {!value.catalog_categories.every(items => items.items.every(values => values.isActive == false))
                        ? svgFadeEyeFun1() : svgFillEyeFun2()}</span>
                    {svgDragFun()}
                </a>
            </div>
            <div style={{ marginBottom: ".8rem" }}>
                <div id={`yoo2${index}`} className="collapse cdcd">
                    <ChangedStoreToggle2 value={value?.catalog_categories} handleUpdate={handleUpdate} hold1={index} handleChangeCatagory={handleChangeCatagory} handleToggleChange={handleToggleChange} handleChangeitem={handleChangeitem} />
                </div>
            </div>
        </ div>
    )
}