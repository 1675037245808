
import { FetchAPI,postAPI } from '../Api/api';


//this API checks if the business application has been approved or not

export async function checkValidity(){

try {
    
    let info = JSON.parse(localStorage.getItem("user"));
    let response= await FetchAPI(`/temp-businesses/?user=${info.userID}`,info.token)
    if(response.data[0].isApproved){

        let status_object={
            accepted:true,
            twbusiness:response.data[0].twbusiness.id,
            placeId: response.data[0]?.twbusiness?.placeId,
            appClipUid:response.data[0]?.twbusiness?.appClipUid
        }

        return status_object;
    }else return{accepted:false,twbusiness:null}

} catch (error) {
    
  throw error
   
}


}


 export async function create_cat(cat){

   try {
     
    let category = await postAPI(`/catalog-categories`, JSON.stringify(cat))
      return{
          label:cat.name,
          value:category.data.id
      }
    

   } catch (error) {
 
      throw error;   
   }

}



export async function create_subcat(subcat){

  try {

    //just for the API 
    let subcategory= await postAPI('/catalog-categories',JSON.stringify(subcat))

    
    return  {
        label:subcat.name,
        value:subcategory.data.id
    }
   
    
  } catch (error) {
      throw error;
      
  }
}


export function sanitizeData(data){
    let value={...data};
    value.category=data.category.value
    value.subcategory=data.subcategory.value

    return value;
 
}


//exports a default object for matching react select criteria.



//retruns a dollar added at the prefix and vice versa
export function contains_dollar(value){
    if(!value.startsWith("$"))return  value ? "$"+value : ""
    else return value

}



//custom validator function  for yup 
export function Validator(value){
const emailRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)

 const phoneRegex = new RegExp(/^(\+91-|\+91|0)?\d{10}$/);


  let isValidPhone = phoneRegex.test(value);
  let isValidEmail = emailRegex.test(value);


  if (!isValidEmail && !isValidPhone) {
    return false
  }
  return true
}
