import React from 'react';
import { Formik, Field, Form } from 'formik';
import Spinner from './Components/Spinner';
import Alert from './Components/alert';
import { FetchAPI, postAPI } from '../Api/api';
import * as Yup from 'yup';
import { update } from '../Api/api';
import { toast } from 'react-toastify';
import { updateAPI, deleteAPI } from '../Api/api';
import {withRouter} from 'react-router';


let initial = {
  FirstName: '',
  LastName: '',
  Phone: '',
  email: '',
  oldPassword: '',
  newPassword: ''

}


//formik component is being used over here. refer formik docs for more information
const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required('Required'),
  LastName: Yup.string().required('Required'),
  Phone: Yup.string().required('Required'),
  email: Yup.string().email('Enter a valid email address').required('Required'),

})


//! we can also cancel the request if the user goes to a different source.
 class Setting extends React.Component {


  constructor() {
    super();
    this.state = {
      userID: '',
      loading: true,
      tempBusiness: null,
      twbusiness: null,
      alert: {
        type: '',
        message: ''
      },
      resetPass: false,
      loader: false,
      loader2: false,
      cPass:false,
      nPass:false
    }
  }



  componentDidMount() {

    let info = JSON.parse(localStorage.getItem('user'))

    this.setState({ userID: info.userID })


    FetchAPI(`/users/${info.userID}`, info.token).then(result => {

      initial.FirstName = result.data.FirstName
      initial.LastName = result.data.LastName
      initial.Phone = result.data.Phone
      initial.email = result.data.email

      this.setState({ tempBusiness: result.data.temp_business ? result.data.temp_business : null }, () => {
        this.setState({ twbusiness: result.data.twbusiness })
      })

      this.setState({ loading: false })


    }).catch(error => {

      console.log(error);
    })


  }

  //runs when a component re-renders.
  componentDidUpdate(prevState) {

    if (this.state.alert.type.length > 0 && this.state.alert.message.length > 0) {
      setTimeout(() => {
        this.setState({ alert: { type: '', message: '' } })
      }, 2000)
    }
  }

  DeleteAccount = async () => {
    if(window.confirm("Are you sure, You want to delete this account?")){
      let info = JSON.parse(localStorage.getItem('user'))


      let businessProfile = Object.assign({}, this.state.twbusiness);
      businessProfile.IsLinked = false;
      businessProfile.isDisabled = true;
  
      if(this.state?.twbusiness?.id){
        this.setState({ loader2: true })
  
        await update(`/deleteUser?userId=${info?.userID}`, businessProfile)
      update(`/twbusinesses/${this.state.twbusiness?.id}`, businessProfile).then((result) => {
        // if (result.data) return deleteAPI(`/temp-businesses/${this.state.tempBusiness?.id}`, info?.token)
      }).then(result => {
        let object = {
          FirstName: initial.FirstName,
          LastName: initial.LastName,
          Phone: initial.Phone,
          email: initial.email,
          tempBusiness: null,
          twbusiness: null
        }
  
        if (result?.data) return update(`/users/${this.state?.userID}`, object).then(result => {
  
        }).then(result => {
          toast.success(" Account Deleted !");
          this.setState({ loader2: false },()=>{
            this.props.history.push('/')
          })
        }).catch((error) => {
  
          this.setState({ loader2: false })
          console.log(error);
        })
  
      }
      )
        localStorage.removeItem("user");
        this.props.history.push({
          pathname: "/sign-in",
          state: { newUser: false, login: false },
        });
      } else {
        toast.error("You business isn't active or you haven't created yet.");
      }
    } else {

    }
    
  }

  handleSubmit = (value) => {

    this.setState({ loader: true })


    let object = {
      FirstName: value.FirstName,
      LastName: value.LastName,
      Phone: value.Phone,
      email: value.email,
      oldPassword: value.oldPassword,
      newPassword: value.newPassword
    }


    let infoToken = JSON.parse(localStorage.getItem('user')).token;
    postAPI(`/changePassword`, object, infoToken).then(result => {

      this.setState({ loader: false })

      toast.success(" Changes Saved !");

    }).catch(error => {

      console.log(error);
      toast.error("We ran into an error");

    })


    // postAPI(`/auth/forgot-password`, { email: 'subrat.singh@team.cliffex.com', url: 'https://devapi.bodegaapp.com/admin/plugins/users-permissions/auth/reset-password' }).then(response => {


    //   console.log(response);

    // }).catch(error => {

    //   console.log(error);

    // })




  }

  render() {
    return (
      <>

        {
          this.state.loading ? <div className="d-flex justify-content-center  mt-5 w-100"> <Spinner small={false}></Spinner> </div> :
            <Formik initialValues={initial} onSubmit={this.handleSubmit}   >
              {(formik) => (
                <div>
                  <div className="d-flex justify-content-center">
                    <Alert type={this.state.alert.type} message={this.state.alert.message}></Alert>
                  </div>
                  <div className="main_head d-flex animate__animated animate__fadeIn mt-4 ">
                    <p className="title m-0">Settings</p>
                    <div className="btn-group ml-auto" role="group" aria-label="Basic example">
                      <button type="button" className="cs_btn out_line2 btn_focus">Cancel</button>
                      <button type="submit" onClick={formik.handleSubmit} disabled={!formik.dirty || this.state.loader ? true : false} className="cs_btn bg_prime border-0 btn_focus"> {this.state.loader ? <Spinner small={true}></Spinner> : 'Save Changes'}
                      </button>
                    </div>
                  </div>
                  <div className="card_wrap">
                    <div className="card_header1">
                      <span className="header_title">Settings</span>
                    </div>

                    <div className="card_body_1">
                      <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                        <div className="left_edit">
                          <label className="mb-0 items_text">First Name</label>
                        </div>
                        <div className="right_edit w-100">
                          <Field id="FirstName" name="FirstName" type="text" className="form-control items_text cs_input border_1 focus_out mb-0" autoComplete="true" placeholder="First Name" />
                        </div>
                      </div>
                      <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                        <div className="left_edit">
                          <label className="mb-0 items_text">Last Name</label>
                        </div>
                        <div className="right_edit w-100">
                          <Field id="LastName" name="LastName" type="text" className="form-control items_text cs_input border_1 focus_out mb-0" autoComplete="true" placeholder="Last Name" />
                        </div>
                      </div>
                      <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                        <div className="left_edit">
                          <label className="mb-0 items_text">Email</label>
                        </div>
                        <div className="right_edit w-100">
                          <Field id="email" name="email" type="email" className="form-control items_text cs_input border_1 focus_out mb-0" autoComplete="true" placeholder="Email" />
                        </div>
                      </div>



                      
                    {this.state.resetPass &&
                      <div>

                        <div className="edit-item d-flex align-items-center  mb-4 form_col_sm animate__animated animate__fadeIn">
                          <div className="left_edit">
                            <label className="mb-0 items_text">Confirm Password</label>
                          </div>
                          <div className="right_edit w-100 position-relative">
                            <Field type={this.state.cPass? "text":"password"} id="oldPassword" name="oldPassword" className="form-control items_text cs_input border_1 focus_out mb-0" placeholder="Previous Password" />
                            <i className={this.state.cPass ?'bi bi-eye-slash eye_icon':'bi bi-eye-fill eye_icon'} onClick={() => this.setState({cPass: !this.state.cPass})}></i>
                          </div>
                        </div>




                        <div className="edit-item d-flex align-items-center form_col_sm animate__animated animate__fadeIn">
                          <div className="left_edit">
                            <label className="mb-0 items_text"> New Password </label>
                          </div>
                          <div className="right_edit w-100 position-relative">
                            <Field type={this.state.nPass ? "text":"password"} id="newPassword" name="newPassword" className="form-control items_text cs_input border_1 focus_out mb-0" placeholder="New Password" />
                            <i className={this.state.nPass ?'bi bi-eye-slash eye_icon':'bi bi-eye-fill eye_icon'} onClick={() => this.setState({nPass: !this.state.nPass})}></i>
                          </div>
                        </div>

                      </div>
                    }




                      <div className="edit-item d-flex align-items-center mb-4 form_col_sm">
                        <div className="left_edit">
                          <label className="mb-0 items_text" hidden={true} >Password</label>
                        </div>
                        
                        <div className="right_edit w-100 d-flex">

                          <button className="cs_button btn_focus"  onClick={() => this.setState({ resetPass: true })} type="button">Reset password</button>
                          {this.state.loader2 
                            ? <div className="d-flex justify-content-center mt-2" style={{margin:"0 auto"}}>  <Spinner small={true} />  </div>
                            : <span className="text_danger mx-auto btn_focus">
                                <button onClick={() =>  this.DeleteAccount()}
                                  className="text_danger cs_button btn_focus" type="button">Delete Account</button>
                              </span>
                              }
                                 
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

              )}
            </Formik>
        }
      </>
    )
  }
}



export default withRouter(Setting)