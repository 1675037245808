import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Work.css";

export default function ContentLibraryModal(props) {
	const [modalData, setModalData] = useState([]);
	const [itemData, setItemData] = useState();
	const [loader, setLoader] = useState(true);
	const [categories, setCategories] = useState([]);
	const [filteredCategory, setFilteredCategory] = useState([]);

	let descriptionLength;
	let browserWidth = window.innerWidth;
	let responsiveScreen = {
		width0: {
			size: 11,
		},
		width400: {
			size: 28,
		},
		width767: {
			size: 33,
		},
		width992: {
			size: 23,
		},
		width1366: {
			size: 52,
		},
	};

	if (browserWidth <= 400) {
		descriptionLength = responsiveScreen.width0.size;
	} else if (browserWidth >= 400 && browserWidth <= 766) {
		descriptionLength = responsiveScreen.width400.size;
	} else if (browserWidth >= 767 && browserWidth <= 991) {
		descriptionLength = responsiveScreen.width767.size;
	} else if (browserWidth >= 992 && browserWidth <= 1365) {
		descriptionLength = responsiveScreen.width992.size;
	} else if (browserWidth >= 1366) {
		descriptionLength = responsiveScreen.width1366.size;
	}

	useEffect(() => {
		handleAPI();
	}, []);

	useEffect(() => {
		if (itemData?.id) {
			setFilteredCategory(
				modalData.filter((el) => el?.catalog_category?._id === itemData?._id)
			);
		}
	}, [itemData]);

	const handleAPI = () => {
		let thisisdata = axios({
			method: "GET",
			url: "https://api.locol.partners/content-libraries?_limit=1000",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((res) => {
			setModalData(res.data);
			let filter = res?.data
				?.filter(
					(value, index, self) =>
						index ===
						self.findIndex(
							(t) => t?.catalog_category?._id === value?.catalog_category?._id
						)
				)
				.map((el) => el?.catalog_category);
			setCategories(filter);
			setItemData(filter?.length ? filter[0] : []);
			setLoader(false);
		});
	};

	return (
		<>
			<div className="row">
				<div
					className="modal modal-xl fade"
					id="exampleModalCenter"
					tabIndex={-1}
					aria-labelledby="exampleModalCenter"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header row m-0">
								<div className="col-1"></div>
								<div className="col-10 heading p-0 ">
									<h2
										className="modal-title"
										id="exampleModalLabel"
										style={{ fontWeight: "700" }}
									>
										Library
									</h2>
									<p style={{ fontWeight: "400" }}>
										We always recommend using your own content, authentic works.
										But if it’s not possible then feel free to use any of below
										content for free.
									</p>
								</div>
								<button
									type="button"
									className="btn-close col-1 mb-auto"
									data-dismiss="modal"
									aria-label="Close"
								/>
							</div>
							<div className="modal-body">
								<div className="contain">
									<section className="row m-0 p-0">
										<div className="col-md-4 col-lg-3 col-xl-3 col-sm-12 my-4 text-center menu">
											<h3 style={{ textAlign: "left", fontWeight: "700" }}>
												Categories
											</h3>
											<ul className="text-start text-lg-center ps-0">
												{categories.map((d, i) => (
													<>
														{d ? (
															<li style={{ fontWeight: "700" }} key={i}>
																<button
																	id="v-pills-restaurants-tab"
																	data-bs-toggle="pill"
																	data-bs-target="#v-pills-restaurants"
																	type="button"
																	role="tab"
																	aria-controls="v-pills-restaurants"
																	aria-selected="true"
																	style={{ fontWeight: "600" }}
																	className={`nav-link ${
																		itemData?.name == d?.name ? "active" : ""
																	} cate-btn`}
																	onClick={() => setItemData(d)}
																>
																	{d?.name}
																</button>
															</li>
														) : (
															""
														)}
													</>
												))}
											</ul>
										</div>
										<div
											className="col-sm-12 col-md-8 col-lg-9 px-2 px-lg-0"					
										>
											<div style={{ height: "70vh",overflowY: '-webkit-auto', overflowY: "auto" }}>
											<div className="row m-3">
												{filteredCategory.map((da, ind) => {
													return (
														<>
															<div
																className="col-lg-6 col-sm-12 px-0 "
																key={ind}
																style={{ minHeight: "90%" }}
															>
																<div className="row m-2 rounded border libraryCard ">
																	<div
																		className="col-lg-6 col-12 px-0"
																		style={{ minHeight: "250px" }}
																	>
																		<div
																			className={
																				da?.ItemVideo?.mime.split("/")[0] ==
																				"video"
																					? "videoBtn"
																					: ""
																			}
																		>
																			<img
																				src={da.ItemThumbnail.url}
																				alt={da.ItemThumbnail.url}
																				className="w-100 h-auto rounded"
																				style={{
																					objectFit: "content", maxHeight: "272px"
																				}}
																			/>
																			<a
																				href={da?.ItemVideo?.url}
																				target="_blank"
																			></a>
																		</div>
																	</div>
																	<div
																		className="col-lg-6 col-12 p-3"
																		style={{
																			wordWrap: "break-word",
																			fontSize: "16px",
																		}}
																	>
																		<div className="d-flex h-100 justify-content-between flex-column text-left">
																			<div className="row">
																				<h5
																					style={{
																						fontWeight: "600",
																						fontSize: "16px",
																					}}
																				>
																					{da.ItemName}
																				</h5>
																				<p
																					style={{
																						fontWeight: "400",
																						fontSize: "14px",
																					}}
																				>
																					{da.ItemDescription &&
																					da?.ItemDescription?.length >
																						descriptionLength
																						? da?.ItemDescription.slice(
																								0,
																								descriptionLength
																						  ) + "..."
																						: da?.ItemDescription}
																				</p>
																			</div>
																			<div className="row p-2">
																				<button
																					className="rounded-3 border-primary text-primary bg-white px-3 py-1 button-0 d-block "
																					type="button"
																					data-dismiss="modal"
																					aria-label="Close"
																					onClick={() =>
																						props.handleModalStates(
																							da,
																							props.formik
																						)
																					}
																					style={{
																						fontWeight: "400",
																						fontSize: "13px",
																					}}
																				>
																					Select this asset
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													);
												})}
											</div>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
