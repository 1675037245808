import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
	postAPI,
	FetchAPI,
	FetchAPILocal,
	uploadImage,
	deleteImage,
	deleteAPI,
} from "../../Api/api";
import { useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { useRef } from "react";
import Error from "../Components/error";
import {
	create_cat,
	create_subcat,
	sanitizeData,
	Validator,
} from "../../Utils/utils";
import { initialValues, validation, prototype } from "./utils";
import ContentLibraryModal from "./ContentLibraryModal";

function Additem() {
	let history = useHistory();

	const [buttonLoader, setButtonLoader] = useState(false);
	const [catLoad, setCatLoad] = useState(false);
	const [category, setCategory] = useState([]);
	const [subCategory, setSubcategory] = useState([]);
	const [loader, setLoader] = useState(true);
	const [miniLoader, setMiniLoader] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [modalObj, setModalObj] = useState("");
	const [videoMiniLoader, setvideoMiniLoader] = useState(false);

	//stores the video info to check.
	const [videoInfo, setVideoInfo] = useState([]);

	//storing the local sutrage data into refs as the toekn has to be used many times.
	const localInfo = useRef(null);
	const isNewCat = useRef(null);
	const isNewSubcat = useRef(null);

	useEffect(() => {
		localInfo.current = JSON.parse(localStorage.getItem("user"));

		FetchAPI(
			`/temp-businesses/?user=${localInfo.current.userID}`,
			localInfo.current.token
		)
			.then((response) => {
				if (response.data.length > 0 && response.data[0].isApproved == true) {
					FetchCategories(localInfo.current.twbusiness);
				} else history.push("/business-profile");
			})
			.catch((err) => {
				console.log("we ran into an error, please try again later");
			});
	}, []);

	async function handelSubmit(value) {
		try {
			setButtonLoader(true);

			//if the catelog contains a video, we upload  at the time of submission
			if (videoInfo.length > 0) {
				let uploadStatus = await uploadVideo();
				if (uploadStatus) {
					// console.log(uploadStatus)
					value.image_url = uploadStatus.data[0].url;
					value.photoId = uploadStatus.data[0].id;
					value.is_video = true;
				}
			}

			if (isNewCat.current) {
				let data = await create_cat(isNewCat.current);
				value.category = data;
			}
			if (isNewSubcat.current) {
				let data = await create_subcat({
					name: isNewSubcat.current.name,
					parent_id: value.category.value,
				});
				// console.log(data)
				value.subcategory = data;
				// console.log(value.subCategory)
			}

			value.twbusiness = localInfo.current.twbusiness;

			//checks if the price starts with dollar if it does not it assigns one.
			// if (value.price.startsWith("$")) value.price = "$" + value.price;

			let requestBody = sanitizeData(value);

			let final = await postAPI(`/catalogues`, JSON.stringify(requestBody));

			if (final) {
				toast.info("Item added Notification !");
				setButtonLoader(false);
				history.push("/");
			}
		} catch (error) {
			console.log(error);
			setButtonLoader(false);
		}
	}

	function FetchCategories(bussID) {
		//catelog categoreis api will be called.

		//  setCatLoad(true)

		FetchAPI(
			`/catalog-categories?searchType=1&id=${bussID}&_sort=createdAt:desc`,
			localInfo.current.token
		)
			.then((res) => {
				setCategory(res.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				//  setCatLoad(false)
				setLoader(false);
			});
	}

	//sets the formik value if a new category is created.
	const handleCatChange = async (newValue, formik) => {
		try {
			if (newValue) {
				//incase there is a new value, we are storing it in the ref .Upon submitting these values would be posted with the catelog.
				if (newValue.__isNew__) {
					setCatLoad(true);
					isNewCat.current = {
						name: newValue.label,
						twbusiness: localInfo.current.twbusiness,
					};

					//sets the feilds value of a specifed name in formik.
					formik.setFieldValue(
						"category",
						{ label: newValue.label, value: newValue.value },
						true
					);
					formik.setFieldValue("subcategory", { label: "", value: "" }, true);
					setSubcategory([]);
					setDisabled(false);
				} else {
					//if there is an previous value, subcategories for that category are fetched aand set into a locval state.
					setCatLoad(true);
					isNewCat.current = null;
					formik.setFieldValue(
						"category",
						{ label: newValue.label, value: newValue.value },
						true
					);
					formik.setFieldValue("subcategory", prototype(), true);
					let category = await FetchAPI(
						`/catalog-categories?searchType=2&id=${newValue.value}&_sort=createdAt:desc`,
						localInfo.current.token
					);

					if (category.data.length > 0) {
						setSubcategory(category.data);
					}

					setCatLoad(false);
					setDisabled(false);
				}
			} else {
				formik.setFieldValue("category", prototype(), true);
				formik.setFieldValue("subcategory", prototype(), true);
				isNewCat.current = null;
				isNewSubcat.current = null;
				setDisabled(true);
			}
			setCatLoad(false);
		} catch (error) {
			setCatLoad(false);
			console.log(error);
		}
	};

	const handleSubCatChange = async (newValue, formik) => {
		try {
			if (newValue) {
				if (newValue.__isNew__) {
					setDisabled(true);
					//console.log(obj);
					//  setCatLoad(true)

					//creates a new subcat object and assigns in the ref.
					isNewSubcat.current = {
						name: newValue.label,
						parent_id: formik.values.category,
					};

					//this value has no
					formik.setFieldValue(
						"subcategory",
						{ label: newValue.label, value: newValue.value },
						true
					);
					setDisabled(false);
				} else {
					isNewSubcat.current = null;
					formik.setFieldValue("subcategory", { ...newValue });
				}
			} else {
				//sets the value of feilds with empty keys.
				formik.setFieldValue("subcategory", prototype(), true);
				isNewSubcat.current = null;
			}

			setCatLoad(false);
		} catch (err) {
			console.log(err);
			setCatLoad(false);
		}
	};

	async function cancelHandler(formik) {
		try {
			if (
				formik.values.photoId.length > 0 &&
				formik.values.image_url.length > 0
			) {
				setMiniLoader(true);
				console.log("deleting from the api");
				setMiniLoader(true);
				await deleteImage(`/upload/files/${formik.values.photoId}`).catch(
					(err) => {}
				);
			}
			setMiniLoader(false);

			history.goBack();
		} catch (error) {}
	}

	//uplaods a new file but first deltes the exsiting one .
	async function fileUpload(e, formik) {
		console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", e);

		let lastIndex = e.target.files.length - 1;
		console.log(e.target?.files[lastIndex]?.type);

		if (e.target?.files[lastIndex]?.type?.includes("video")) {
			//saves video info in the state which is called on Handle Submit
			const file = e.target.files[e.target.files.length - 1];
			const newFile = new File([file], file.name + ".mp4", {
				type: file.type,
				lastModified: file.lastModified,
			});
			setVideoInfo([newFile]);

			// -------getting video height width----------
			const formData = new FormData();
			formData.append("files", e.target.files[e.target.files.length - 1]);

			const url = URL.createObjectURL(newFile);
			const video = document.createElement("video");
			video.onloadedmetadata = () => {
				URL.revokeObjectURL(url);
				const isPortrait = video.videoWidth >= video.videoHeight ? false : true;
				formik.setFieldValue("isPortrait", isPortrait);
				console.log(
					video.videoWidth + "X" + video.videoHeight,
					", IsPortrait:",
					isPortrait
				);
			};
			video.src = url;
			video.load();
			setvideoMiniLoader(true);
			uploadImage(`/upload`, formData)
				.then((result) => {
					console.log(result);
					formik.setFieldValue("thumbnail", "");
					setTimeout(() => {
						formik.setFieldValue("image_url", result.data[0].url);
						formik.setFieldValue("is_video", true);
						formik.setFieldValue("photoId", result.data[0].hash);
						formik.setFieldValue("thumbnail", result.data[0].hash);
						setvideoMiniLoader(false);
					}, 5000);
					console.log("insied function");
				})
				.catch((err) => {
					console.log(err);
				})
				.then((result) => {
					setMiniLoader(false);
					document.getElementById("up_file").value = [];
				});
			toast.info("Your Video will be uploaded after you save the changes", {
				autoClose: 3000,
			});
		} else if (
			e?.target?.files[lastIndex] &&
			e?.target?.files[lastIndex]?.type.includes("image")
		) {
			if (e?.target?.files[lastIndex]?.type.includes("webp")) {
				toast.error(`webp files are not supported.`, {
					position: toast.POSITION.TOP_CENTER,
				});
			} else {
				setMiniLoader(true);
				if (
					formik?.values?.photoId?.length > 0 &&
					formik?.values?.image_url?.length > 0
				) {
					await deleteImage(`/upload/files/${formik.values.photoId}`).catch(
						(err) => {
							console.log("error in deleting", err);
						}
					);
				}
				if (videoInfo) {
					setVideoInfo([]);
				}
				const formData = new FormData();
				formData.append("files", e.target.files[lastIndex]);

				//----- getting image height width--------
				const img = new Image();
				var objectUrl = URL.createObjectURL(e.target.files[lastIndex]);
				img.onload = function () {
					const isPortrait = this.width >= this.height ? false : true;
					formik.setFieldValue("isPortrait", isPortrait);
					console.log(
						this.width + "X" + this.height,
						", IsPortrait:",
						isPortrait
					);
				};
				img.src = objectUrl;
				//-----------------------------------------

				uploadImage(`/upload`, formData)
					.then((result) => {
						console.log(result);
						formik.setFieldValue("image_url", result.data[0].url);
						formik.setFieldValue("photoId", result.data[0].id);
						console.log(formik.values);
					})
					.catch((err) => {
						console.log(err);
					})
					.then(() => {
						document.getElementById("up_file").value = [];
						setMiniLoader(false);
					});
			}
		} else if (e.target?.files?.length) {
			let type = e?.target?.files[lastIndex]?.type?.split("/");
			toast.error(`${type[1]} files are not supported.`, {
				position: toast.POSITION.TOP_CENTER,
			});
		}
	}

	const uploadThumbnail = (e, formik) => {
		const image = e.target.files && e.target.files.length && e.target.files[0];
		if (image && image.type.includes("image")) {
			if (e?.target?.files[0]?.type.includes("webp")) {
				toast.error(`webp files are not supported.`, {
					position: toast.POSITION.TOP_CENTER,
				});
			} else {
				const formData = new FormData();
				//----- getting image height width--------
				const img = new Image();
				var objectUrl = URL.createObjectURL(image);
				img.onload = function () {
					const isThumbnailPortrait = this.width >= this.height ? false : true;
					formik.setFieldValue("isThumbnailPortrait", isThumbnailPortrait);
					console.log(
						this.width + "X" + this.height,
						", IsPortrait:",
						isThumbnailPortrait
					);
				};
				img.src = objectUrl;
				//-----------------------------------------

				formData.append("files", image);
				uploadImage(`/upload`, formData)
					.then((result) => {
						formik.setFieldValue("videoThumbnail", result.data[0].url);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			toast.error(`Please select image`, {
				position: toast.POSITION.TOP_CENTER,
			});
		}
	};

	function uploadVideo() {
		const formData = new FormData();
		formData.append("files", videoInfo[videoInfo.length - 1]);
		return uploadImage(`/upload`, formData);
	}

	//deletes the profile pic from the s3
	function deleteFile(formik) {
		setMiniLoader(true);

		deleteImage(`/upload/files/${formik.values.photoId}`)
			.then((result) => {
				console.log(result);
			})
			.catch((err) => {
				console.log(err);
			})
			.then((res) => {
				formik.setFieldValue("image_url", "");
				formik.setFieldValue("photoId", "");

				setMiniLoader(false);
			});
	}

	const handleModalStates = async (obj, formik) => {
		if (obj?.ItemVideo?.url) {
			formik.setFieldValue("is_video", true, true);
			let response = await fetch(obj?.ItemVideo?.url);
			console.log(response, "dlkfjsldkfjl");
			let data = await response.blob();
			console.log(data, "datadatadata");

			let metadata = {
				type: "video/mp4",
			};
			let newFile = new File([data], obj?.ItemVideo?.name, metadata);
			setVideoInfo([newFile]);

			// -------getting video height width----------
			const url = URL.createObjectURL(newFile);
			const video = document.createElement("video");
			video.onloadedmetadata = () => {
				URL.revokeObjectURL(url);
				const isPortrait = video.videoWidth >= video.videoHeight ? false : true;
				formik.setFieldValue("isPortrait", isPortrait);
				console.log(
					video.videoWidth + "X" + video.videoHeight,
					", IsPortrait:",
					isPortrait
				);
			};
			video.src = url;
			video.load();

			toast.info("Your Video will be uploaded after you save the changes", {
				autoClose: 3000,
			});
		}
		setModalObj(obj);
		formik.setFieldValue("name", obj?.ItemName, true);
		formik.setFieldValue("description", obj?.ItemDescription, true);
		formik.setFieldValue("image_url", obj?.ItemVideo?.url, true);
		formik.setFieldValue("photoId", obj?.ItemVideo?._id, true);
		formik.setFieldValue("videoThumbnail", obj?.ItemThumbnail?.url, true);
		if (obj?.streaming_url && obj?.streaming_url?.length) {
			formik.setFieldValue("streaming_url", obj?.streaming_url, true);
		}
		let category = await FetchAPI(
			`/catalog-categories?searchType=2&id=${obj?.catalog_category?._id}&_sort=createdAt:desc`,
			localInfo?.current?.token
		);
		if (category?.data?.length > 0) {
			setSubcategory(category?.data);
		}
		setDisabled(false);
	};

	return (
		<>
			{loader ? (
				<div className="d-flex justify-content-center w-100 mt-5">
					<Spinner></Spinner>
				</div>
			) : (
				<Formik
					initialValues={initialValues}
					onSubmit={handelSubmit}
					validationSchema={validation}
				>
					{(formik) => (
						<Form>
							<div className="main_head d-flex">
								<p className="title m-0">Add Item</p>
								<div
									className="btn-group ml-auto"
									role="group"
									aria-label="Basic example"
								>
									<button
										type="button"
										className="btn btn-primary"
										data-toggle="modal"
										data-target="#exampleModalCenter"
										style={{
											borderRadius: "5px",
											fontSize: "13px",
											marginRight: "1.5rem",
										}}
									>
										Content Library
									</button>
									<ContentLibraryModal
										handleModalStates={handleModalStates}
										formik={formik}
									/>
									<button
										type="button"
										onClick={() => cancelHandler(formik)}
										className="cs_btn out_line2 btn_focus"
									>
										Cancel
									</button>
									<button
										type="submit"
										className="cs_btn bg_prime border-0 btn_focus"
										disabled={!formik.isValid || buttonLoader}
									>
										{buttonLoader ? <Spinner small={true} /> : "Save Changes"}
									</button>
								</div>
							</div>

							<div className="card_container mb-5 card_edit animate__animated animate__fadeIn">
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">
											Item Image / Video
										</label>
									</div>
									<div className="right_edit d-flex align-items-center">
										<div className="items_images position-relative bg_light">
											<div
												className={
													!formik.values?.image_url?.length > 0
														? "items_images position-relative bg_light"
														: ""
												}
											>
												<img
													src={
														formik.values.is_video
															? `https://stream.locol.partners/${formik.values.photoId}/thumb/${formik.values.photoId}.jpg`
															: formik.values.image_url
													}
													style={{
														objectFit: "cover",
														width: "100%",
														borderRadius: "15px",
													}}
													className="items_images position-relative "
												></img>
												<button
													htmlFor="up_file"
													type="button"
													className="edit_btn focus_out over_flow_hidden"
												>
													<label
														htmlFor="up_file"
														className="bi bi-pencil-fill mb-0"
													></label>
													<input
														accept="image"
														type="file"
														onChange={(e) => fileUpload(e, formik)}
														className={`upload_input`}
														required={
															videoInfo?.length > 0 || formik.values.image_url
																? false
																: true
														}
														id="up_file"
													></input>
												</button>
											</div>
										</div>
										<button
											type="button"
											className="cs_btn out_line1 ml-0 ml-5 custom-spacing"
											disabled={formik.values?.photoId?.length ? false : true}
											onClick={() => deleteFile(formik)}
										>
											<i className="mr-1 bi bi-trash"></i>
											{miniLoader || videoMiniLoader ? (
												<Spinner small={true} />
											) : (
												"Delete"
											)}
										</button>
									</div>
								</div>

								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">
											Item thumbnail (optional)
										</label>
									</div>
									<div className="right_edit d-flex align-items-center">
										<div className="items_images position-relative bg_light">
											<div className="items_images position-relative bg_light">
												<img
													src={
														formik.values.videoThumbnail
															? formik.values.videoThumbnail
															: formik.values.thumbnail
															? `https://stream.locol.partners/${formik.values.thumbnail}/thumb/${formik.values.thumbnail}.jpg`
															: formik.values.image_url
													}
													className="items_thumbnail_images position-relative "
													style={{
														objectFit: "cover",
														width: "100%",
														height: "100%",
														borderRadius: "15px",
													}}
												/>
												<button
													htmlFor="up_file"
													type="button"
													className="edit_btn focus_out over_flow_hidden"
												>
													<label
														htmlFor="up_file"
														className="bi bi-pencil-fill mb-0"
													></label>
													<input
														accept="image"
														type="file"
														onChange={(e) => uploadThumbnail(e, formik)}
														className="upload_input"
														id="up_thumbnail_file"
													></input>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Item name</label>
									</div>
									<div className="right_edit w-100">
										<Field
											id="name"
											name="name"
											maxLength={500}
											type="text"
											className={`form-control ${
												formik.touched.name && formik.errors.name
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Enter Item name"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Description</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											maxLength={500}
											id="description"
											name="description"
											className={`form-control ${
												formik.touched.description && formik.errors.description
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Enter description"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Category</label>
									</div>
									<div className="right_edit w-100">
										<CreatableSelect
											value={formik.values.category}
											placeholder="Select a Category"
											name="category"
											isDisabled={catLoad}
											isLoading={catLoad}
											isClearable
											onChange={(newValue) => {
												if (newValue) {
													let newObj = { ...newValue };
													newObj.value = newObj.value.slice(0, 30);
													newObj.label = newObj.label.slice(0, 30);
													handleCatChange(newObj, formik);
												}
											}}
											className={`${
												formik.touched.category && formik.errors.category
													? "border border-danger"
													: ""
											}`}
											options={category}
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Sub-category</label>
									</div>
									<div className="right_edit w-100">
										<CreatableSelect
											value={formik.values.subcategory}
											name="subcategory"
											isDisabled={disabled || catLoad}
											isLoading={catLoad}
											isClearable
											onChange={(newValue) => {
												if (newValue) {
													let newObj = { ...newValue };
													newObj.value = newObj.value.slice(0, 30);
													newObj.label = newObj.label.slice(0, 30);
													handleSubCatChange(newObj, formik);
												}
											}}
											className={`${
												formik.touched.subcategory && formik.errors.subcategory
													? "border border-danger"
													: ""
											}`}
											options={subCategory}
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Price</label>
									</div>
									<div className="right_edit w-100">
										<Field
											type="text"
											name="price"
											// maxLength={6}
											id="price"
											className={`form-control ${
												formik.touched.price && formik.errors.price
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="$1.35"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">CTA</label>
									</div>
									<div className="right_edit w-100">
										<Field
											// maxLength={10}
											type="text"
											name="action"
											className={`form-control ${
												formik.touched.action && formik.errors.action
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="Select call to action"
										/>
									</div>
								</div>
								<div className="edit-item d-flex align-items-center mb-4">
									<div className="left_edit">
										<label className="mb-0 items_text">Link</label>
									</div>
									<div className="right_edit w-100">
										<Field
											maxLength={500}
											type="text"
											name="forwardLink"
											className={`form-control ${
												formik.touched.forwardLink && formik.errors.forwardLink
													? "border border-danger"
													: ""
											} items_text cs_input border_1 focus_out`}
											placeholder="https://www.google.com"
										/>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</>
	);
}

export default Additem;
