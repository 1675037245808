import React from 'react';
import { Link } from 'react-router-dom';
import mainLogo from '../../Assets/image/mainLogo.png';
import { useFormik } from 'formik';
import { useState } from 'react';
import Spinner from '../Components/Spinner';
import * as Yup from 'yup';
import { FetchAPI, LoginAPI, update } from '../../Api/api';
import { useHistory, useLocation } from "react-router-dom";
import Alert from '../Components/alert';
import { useEffect } from 'react';
import {uniqid} from 'uniqid';
import axios from 'axios';
import { baseUrl } from '../../Api/baseUrl';

const SignupSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  identifier: Yup.string().required('Required').email('Invalid email'),
});


function SignIn() {

  const [pass, setPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({
    type: '',
    message: ''
  })

  let history = useHistory();
  const location = useLocation();


  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: ''
    },
    onSubmit: async values => {
      setLoading(true)
      // alert(JSON.stringify(values))

      const checkUser = await axios.get(`${baseUrl}/loginUser?email=${values?.identifier}`)

      if(checkUser?.data?.isActive){

      LoginAPI(`/auth/local`, JSON.stringify(values)).then(res => {

        let userInfo = {
          userID: res.data.user.id,
          token: res.data.jwt,
          twbusiness: res.data.user.twbusiness && res.data.user.twbusiness.id
        }

        localStorage.setItem("user", JSON.stringify(userInfo))
        setLoading(false)
        history.push('/')

      }).catch(err => {
        console.log(err)
        setMessage({ type: 'alert-danger', message: err?.response?.data?.message[0]?.messages[0]?.message })

        setTimeout(() => {
          setMessage({ type: '', message: '' })
        }, 2000)
        setLoading(false)

      })
      }else if(checkUser?.data?.isActive == false){
        setMessage({ type: 'alert-danger', message: "Your account has been disabled, Please contact to your administrator." });
        setLoading(false);

      }else {
        setMessage({ type: 'alert-danger', message: "Email not matched to any account." });
        setLoading(false);
      }
    },
    validationSchema: SignupSchema

  })



  useEffect(() => {
    if (location && location.state) {
      if (location.state.newUser) {
        setMessage({ type: 'alert-success', message: 'Acccount Successfully Created. You can login now' })

      } else if (location.state.login) {
        setMessage({ type: 'alert-danger', message: 'Session Timed out. Login Again' })

      }

    }

  }, [])



  function handlePass() {
    if(pass) setPass(false)
    else setPass(true)
  }

  return (
    <div>
      <template>
        <header
          id="header"
          className="u-header u-header--abs-top-md u-header--bg-transparent u-header--show-hide-md"
        >
          <div className="u-header__section py-2">
            <div id="logoAndNav" className="container">
              {/* Nav */}
              <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                {/* Logo */}
                <Link
                  className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                >
                  <img alt="" className="brand-logo" />
                </Link>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  onClick
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
                {/* End Responsive Toggle Button */} {/* Navigation */}
                <div
                  id="navBar"
                  className="collapse navbar-collapse u-header__navbar-collapse"
                  style={{ "display": "unset" }}
                >
                  <div className="js-scroll-nav navbar-nav align-items-md-center u-header__navbar-nav">
                    <Link to="/register" className="nav-item u-header__nav-item">
                      <button className="btn btn-sm btn-primary transition-3d-hover">
                        Sign up
                      </button>
                    </Link>

                  </div>
                </div>

              </nav>

            </div>
          </div>
        </header>
      </template>

      {/* Header Section */}
      <div className="container">
        <header className="header_section d-flex padding_tb_20 align-items-center">
          <div className="logo_section border-0">
            <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="w-75 img-fluid" />
          </div>
          <div className="log_button_section  border-0 ml-auto">
            <Link Link to="/register" className="btn btn-primary log_btn">Sign up</Link>
          </div>
        </header>
      </div>

      {/* End */}



      {/* ========== END HEADER ========== */}
      {/* ========== MAIN CONTENT ========== */}
      <div className="center_container_box">
        <div className="inner_width_center">
          <form className="js-validate sign-in-form form-placeholder" onSubmit={formik.handleSubmit}>
            {/* Title */}
            <div className="mb-7 text-center ">

              <div className='d-flex justify-content-center'>
               <Alert type={` animate__animated animate__fadeIn  ${message.type}`} message={message.message}></Alert>
              </div>

                    <h1 className="font-size-md-down-3 font-weight-bold mb-3">
                        Sign in
                    </h1>
            </div> 
            <div className="js-form-message form-group">
              <input
                type="text"
                name="identifier"
                id="identifier"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.identifier}
                className={`input_field focus_out ${formik.touched.identifier && formik.errors.identifier ? 'border border-danger animate__animated animate__fadeIn' : ""}`}

                placeholder={formik.touched.identifier && formik.errors.identifier ? formik.errors.identifier : 'Enter your businesses email'}

              />
            </div>
            <div className="js-form-message form-group">
              <div className="position-relative">
                <input
                  type={pass?'text':'password'}
                  className={`input_field focus_out ${formik.touched.password && formik.errors.password ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                  id="password"
                  name="password"
                  placeholder="********"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password
                  }
                  autoComplete="true"
                />
                <i className={pass ?'bi bi-eye-slash eye_icon':'bi bi-eye-fill eye_icon'} onClick={handlePass}></i>
              </div>

            </div>
                <a className='font-size-md-down-3 float-right font-italic' href='/forgotPassword'>Forgot Password ?</a> 
            <div className="row align-items-center mt-5 mb-1  space-bottom-3 space-bottom-lg-0">
              <div className="col-12 text-center position-relative">
                <button
                  type="submit"
                  className="btn btn-primary btn-wide log_btn round_border ex_space_btn"
                  disabled={!formik.isValid || loading}
                >
                  {loading ? <Spinner small={true} /> : 'Sign In'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <template>
      
      </template>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      {/* <footer>
        <div className="container">
          <div className="footer_section d-flex align-items-center">
            <div className="logo_section border-0 sm_mb_4">
              <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="img-fluid" />
            </div>
            <div className="footer_list_option ml-auto unset_ml_auto sm_mb_4">
              <ul className="m-0 p-0 d-flex">
                <li>about</li>
                <li>services</li>
                <li>our work</li>
              </ul>
            </div>
            <div className="footer_social_icons ml-auto unset_ml_auto">
              <ul className="m-0 p-0 d-flex">
                <li><i class="fab fa-facebook-f"></i></li>
                <li>
                  <i className="fab fa-google"></i>
                </li>
                <li>
                  <i className="fab fa-twitter"></i>
                </li>
                <li>
                  <i className="fab fa-github"></i>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </footer> */}
    </div>
  )
}

export default SignIn
