import React from 'react'
import { useFormik } from 'formik';
import { useHistory, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import Spinner from '../Components/Spinner';
import * as Yup from 'yup';
import { FetchAPI, FetchApiWithToken, LoginAPI } from '../../Api/api';
import Alert from '../Components/alert';
import { Animated } from "react-animated-css";
import { baseUrl } from '../../Api/baseUrl';
import axios from 'axios';

const SignupSchema = Yup.object().shape({
  FirstName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email'),
  LastName: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  // confirmPass: Yup.string().oneOf([Yup.ref('password')], 'Mismatched Passwords').required('Required Feild'),
  // username: Yup.string().required('Required')
})




function RegisterUser() {

  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [error, setError] = useState(false);

  const [message, setMessage] = useState({ type: '', message: '' });

  const [password, setPassword] = useState(false);
  const [pass, setPass] = useState(false)


  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      email: '',
      password: '',
      username: ''
    },
    onSubmit: values => {
      setLoading(true)

      let username = generateRandomUsername(values.firstname)

      values.username = values.FirstName + username;



      LoginAPI(`/auth/local/register`, JSON.stringify(values)).then(res => {
        if(res.data.jwt){
          axios({ 
            method:'POST', 
            url:baseUrl+`/temp-businesses/account/${res.data.jwt}`, 
            data: {user: res.data.user},
            headers: {  'Content-Type': 'application/json', 'Authorization': `Bearer ${res.data.jwt}`}
          }).then(res => {
            console.log(res)
          }).catch(err => {
            console.log(err)
          })
        }


        setLoading(false)


        history.push({
          pathname: '/',
          state: { newUser: true, login: false }
        })


      }).catch(err => {

        setMessage({ type: 'alert-danger w-100', message: err.response.data.message[0].messages[0].message })

        setTimeout(() => {

          setMessage({ type: '', message: '' })
        }, 2000)
        setLoading(false)

      })
    },
    validationSchema: SignupSchema

  })





  function toggler() {
    if (password) setPassword(false)
    else setPassword(true)
  }


  function generateRandomUsername() {

    var val = Math.floor(1000 + Math.random() * 9000);

    return val;


  }



  return (

    <div>
      <template>
        <header
          id="header"
          className="u-header u-header--abs-top-md u-header--bg-transparent u-header--show-hide-md"
        >
          <div className="u-header__section py-2">
            <div id="logoAndNav" className="container">
              {/* Nav */}
              <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                {/* Logo */}
                <Link
                  className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                >
                  <img alt="" className="brand-logo" />
                </Link>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  onClick
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
                {/* End Responsive Toggle Button */} {/* Navigation */}
                <div
                  id="navBar"
                  className="collapse navbar-collapse u-header__navbar-collapse"
                  style={{ "display": "unset" }}
                >
                  <div className="js-scroll-nav navbar-nav align-items-md-center u-header__navbar-nav">
                    <Link to="/" className="nav-item u-header__nav-item">
                      <button className="btn btn-sm btn-primary transition-3d-hover">
                        Sign In
                      </button>
                    </Link>

                  </div>
                </div>

              </nav>

            </div>
          </div>
        </header>
      </template>
      {/* Header Section */}
      <div className="container">
        <header className="header_section d-flex  align-items-center">
          <div className="logo_section border-0">
            <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="w-75 img-fluid" />
          </div>
          {/* <div className="log_button_section  border-0 ml-auto">
                <Link Link to="/" className="btn btn-primary log_btn">Sign in</Link>
              </div> */}
        </header>
      </div>

      {/* End */}
      {/* ========== END HEADER ========== */}
      {/* ========== MAIN CONTENT ========== */}


      <div className="center_container_box sign_up_container">
        <div className="inner_width_center">

          <Animated animationIn='fadeIn' isVisible={message?.type?.length > 0 && message?.message?.length > 0} animationOut="fadeIn" animationOutDelay={2000}  >
            <Alert type={message?.type} message={message?.message}></Alert>
          </Animated>


          <form className="js-validate sign-in-form form-placeholder mt-2 input_sign_up" onSubmit={formik.handleSubmit}>
            <div className="mb-7 text-center">



              <h1 className="font-size-md-down-3 font-weight-bold mb-3">
                Sign Up
              </h1>
            </div>

            <div className="js-form-message form-group">
              <input
                type="text"
                name="FirstName"
                id="FirstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.FirstName}
                className={`input_field focus_out ${formik.touched.FirstName && formik.errors.FirstName ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                placeholder=" First Name"

              />
            </div>
            <div className="js-form-message form-group">
              <input
                type="text"
                name="LastName"
                id="LastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.LastName}
                className={`input_field focus_out ${formik.touched.LastName && formik.errors.LastName ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                placeholder=" Last Name"

              />
            </div>
            {/* <div className="js-form-message form-group">
              <input
                type="text"
                className={`input_field focus_out ${formik.touched.username && formik.errors.username ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                id="username"
                name="username"
                placeholder="Enter your username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}

              />
            </div> */}
            <div className="js-form-message form-group">
              <input
                type="text"
                className={`input_field focus_out ${formik.touched.email && formik.errors.email ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                id="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email
                }
              />
            </div>
            <div className="js-form-message form-group d-flex position-relative">
              <input
                type={pass ? "text" :"password"}
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={`input_field focus_out  ${formik.touched.password && formik.errors.password ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                autoComplete="true"
                placeholder="Password"

              />
              <i className={pass ?'bi bi-eye-slash eye_icon':'bi bi-eye-fill eye_icon'} onClick={()=>setPass(!pass)}></i>
            </div>
            {/* <div className="js-form-message form-group">
              <input
                type="password"
                name="confirmPass"
                id="confirmPass"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPass}
                className={`input_field focus_out ${formik.touched.password && formik.errors.confirmPass ? 'border border-danger animate__animated animate__fadeIn' : ""}`}
                autoComplete="false"
                placeholder="Confirm Password"

              />
            </div> */}
            <div className="row align-items-center mb-1 space-bottom-3 space-bottom-lg-0">
              <div className="col-12 text-center mt-2 position-relative">
                <button
                  type="submit"
                  className="btn btn-primary btn-wide log_btn round_border ex_space_btn"
                  disabled={!formik.isValid || loading}
                >
                  {loading ? <Spinner small={true} /> : 'Create account'}
                </button>
              </div>
              <div className="col-sm-12 col-md-12 text-center mt-2">
                <p className="mb-0 text_form">already have an account ?<Link to={{ pathname: '/', state: { newUser: false, login: false } }} className="link_clr">
                  <strong>Sign In</strong>
                </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>



      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}

      {/* <footer>
        <div className="container">
          <div className="footer_section d-flex align-items-center">
            <div className="logo_section border-0 sm_mb_4">
              <img src={require('../../Assets/image/locol-01.b282249b.png').default} className="img-fluid" />
            </div>
            <div className="footer_list_option ml-auto unset_ml_auto sm_mb_4">
              <ul className="m-0 p-0 d-flex">
                <li>about</li>
                <li>services</li>
                <li>our work</li>
              </ul>
            </div>
            <div className="footer_social_icons ml-auto unset_ml_auto">
              <ul className="m-0 p-0 d-flex">
                <li><i class="fab fa-facebook-f"></i></li>
                <li>
                  <i className="fab fa-google"></i>
                </li>
                <li>
                  <i className="fab fa-twitter"></i>
                </li>
                <li>
                  <i className="fab fa-github"></i>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </footer> */}
    </div>


  )
}

export default RegisterUser
